import React from "react";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getData } from "../../FetchService";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Myactivity from "../ProfileCommon/RightSection/Myactivity";
import ProfileImage from "../ProfileImage/ProfileImage";

import './style.css';


function LeftSide(props) {
  const navigate = useNavigate();
  const Data = useSelector((state) => state.userData);
  const unit = useSelector((state) => state.unit);
  const [following, setfollowing] = useState("");
  const [follower, setfollower] = useState("");
  const [rings, setRings] = useState([]);
  const [lastActivity, setLastActivity] = useState([]);
  const [fitnessData, setFitnessData] = useState({});

  const getfollowing = async () => {
    const res = await getData("get-following");
    setfollowing(res.data);
  };
  const getfollower = async () => {
    const res = await getData("get-followers");
    setfollower(res.data);
  };

  const getrings = async () => {
    const res = await getData("get-activity-rings");
    setRings(res.data);
  };

  const getLastActivity = async () => {
    const res = await getData("last-activity");
    setLastActivity(res.data);
  };

  const fitnessStatistics = async () => {
    const res = await getData("fitness-statistics");
    setFitnessData(res?.data);
  };

  // const getPreferences = async () => {
  //   const res = await getData("get-preferences");

  //   setPreferences(res.data);
  // };
  useEffect(() => {
    getLastActivity();
    getfollowing();
    getfollower();
    getrings();
    fitnessStatistics();
    // getPreferences();
  }, []);

  const dateConverter = (time) => {
    var date = new Date(null);
    date.setSeconds(time);
    var hhmmssFormat = date.toISOString().substr(11, 8);
    let hr = hhmmssFormat.split(":")[0];
    let min = hhmmssFormat.split(":")[1];
    let sec = hhmmssFormat.split(":")[2];
    return `${hr != "00" ? hr + "h" : ""} ${min != "00" ? min + "m" : ""} ${
      sec != "00" ? sec + "s" : ""
    }`;
  };

  const convert = (data, action) => {
    if (action == "weight") {
      if(!data){
      return 'N/A';
      }
      if (unit.weight == "kg") {
        return (data * 1000).toFixed(2);
      } else if (unit.weight == "pounds") {
        return (data * 0.00220462 ).toFixed(2);
      } else {
        return data?.toFixed(2);
      }
    } else if (action == "height") {
      if (unit.height == "cm") {
        return data?.toFixed(2) + 'cm';
      }else if (unit.height == "feet") {
        return (data / 30.48).toFixed(2);
      } else {
        return data?.toFixed(2) + ' cm';
      }
    }
  };

  const convertBMI = (weight, height) => {
    let m = (height / 100) * 2;
    return (weight / m).toFixed(2);
  };

  return (
    <div className="col-12 col-md-5 col-lg-4 leftBodyPart flex-fill h-100 overflow-hidden ">
      <div className="row h-100  customScroll">
        <div className="col-12 sectionOne p-3 bg-white">
          <div className="row mx-0">
            <div className="col-12 imgDetail">
              <NavLink
                to="/dashboard/profile"
                className="text-decoration-none text-dark"
              >
              {
                /** Display Profile Image */
              }
              <div className="imgOuter mx-auto border border-theme1 rounded-pill">
              <ProfileImage image={Data?.image} height="100%" width="100%" borderRadius="50%" />
              </div>

              {
                /** End Display Profile Image */
              }
              <div className="name text-center mt-2">
                {Data?.firstName && Data?.lastName
                  ? `${Data?.firstName} ${Data?.lastName}`
                  : Data?.mobile}
              </div>
              </NavLink>
            </div>
            <div className="col-12 followers">
              <div className="row py-2 py-xl-3 border-bottom justify-content-center">
                <div className="col-4 text-center">
                  <div className="text-decoration-none text-dark">
                    <span className="d-block value" style={{color:'#121016'}}>{Data?.posts}</span>
                    <span className="title text-muted mt-1 d-flex justify-content-center">
                      Post
                    </span>
                  </div>
                </div>
                <div className="col-4 text-center border-start border-end">
                  <div className="text-decoration-none text-dark">
                    <span className="d-block value" style={{color:'#121016'}}>{follower?.length}</span>
                    <span className="title text-muted mt-1 d-flex justify-content-center">
                      Followers
                    </span>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className="text-decoration-none text-dark">
                    <span className="d-block value" style={{color:'#121016'}}>{following?.length}</span>
                    <span className="title text-muted mt-1 d-flex justify-content-center">
                      Following
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 followers">
              <div className="row pt-2 pt-xl-3 justify-content-center">
                <div className="col-4 text-center">
                  <NavLink
                    to="/bmidata"
                    className="text-decoration-none text-dark"
                  >
                    <span className="title text-muted">BMI</span>
                    <span className="d-block value" style={{color:'#121016'}}>
                      {Data?.weight
                        ? convertBMI(Data?.weight, Data?.height)
                        : //  ((Data?.weight / Data?.height) * Data?.height) /
                          //   100?.toFixed(2)
                          0}
                    </span>
                  </NavLink>
                </div>
                <div className="col-4 text-center">
                  <a className="text-decoration-none text-dark">
                    <span className="title text-muted">Weight</span>
                    <span className="d-block value" style={{color:'#121016'}}>
                      {convert(Data?.weight, "weight")}{" "}
                      {unit.weight == "kgs" ? "kg" : unit.weight}{" "}
                    </span>
                  </a>
                </div>
                <div className="col-4 text-center">
                  <a className="text-decoration-none text-dark">
                    <span className="title text-muted">Height</span>
                    <span className="d-block value" style={{color:'#121016'}}>
                      {convert(Data?.height, "height")} {unit.height}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 sectionTwo p-3 bg-white mt-4">
          <ul className="list-unstyled p-0 m-0 d-flex justify-content-between">
            <li className=" text-center">
              <div className="imgOuter mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-86.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">{fitnessData.points}</span>
                <span className="title text-muted">Overall Points</span>
              </div>
            </li>
            <li className=" text-center">
              <div className="imgOuter purple mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-87.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">{fitnessData?.rank}</span>
                <span className="title text-muted">Rank</span>
              </div>
            </li>
            <li className=" text-center">
              <div className="imgOuter orange mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-88.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">
                  {fitnessData?.totalActivities}
                </span>
                <span className="title text-muted">Total Activities</span>
              </div>
            </li>
          </ul>
        </div>
        {rings.map((item, i) => {
          return (
            <div
              key={Math.random()}
              className="col-12 sectionThree p-3 bg-white mt-4"
            >
              <div className="row mx-0">
                <div className="col-12 px-0 todayDataHeader pb-2 pb-xl-3 border-bottom">
                  <div className="mainTitle" style={{color:'#121016'}}>Today’s Data</div>
                </div>
                <div className="col-12 pt-3">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="">
                        <CircularProgressbarWithChildren
                          value={item.steps != 0 && item.maxSteps !=0 ?(item.steps / item.maxSteps) * 100:0}
                          // text={item.steps}
                          strokeWidth={8}
                          background
                          styles={buildStyles({
                            pathColor: "#ED5181",
                            trailColor: "white",
                            backgroundColor: "#FDE9F2",
                          })}
                        >
                          <div>
                            <strong>{item.steps}</strong>
                          </div>
                          <div style={{ display: "flex" }}>
                            <img
                              style={{
                                width: "20px",
                                height: "23px",
                                marginRight: "5px",
                              }}
                              src="/assets/images/Vector.png"
                              alt="doge"
                            />
                            <div>steps</div>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12 my-2 d-flex align-items-center gap-2">
                          <div className="w-50">
                            <CircularProgressbarWithChildren
                              value={item.calories != 0 && item.maxCalories !=0 ?(item?.calories / item?.maxCalories) * 100: 0}
                              background
                              styles={buildStyles({
                                pathColor: "#F56539",
                                trailColor: "white",
                                backgroundColor: "#FFE7DB",
                                // weight:'100px',
                                // width:'100px'
                              })}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "23px",
                                  marginRight: "5px",
                                }}
                                src="/assets/images/Vectorfire.png"
                                alt="doge"
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="text">
                            <span className="text-muted w-100 d-block" style={{fontSize:'12px'}}>
                              Calories
                            </span>
                            <b className="">{item?.calories}</b>
                          </div>
                        </div>
                        <div className="col-12 my-2 d-flex align-items-center gap-2">
                          <div className="w-50">
                            <CircularProgressbarWithChildren
                              value={item.distance != 0 && item.maxDistance !=0 ?(item?.distance / item?.maxDistance) * 100:0}
                              strokeWidth={8}
                              background
                              styles={buildStyles({
                                pathColor: "#F4BF37",
                                trailColor: "white",
                                backgroundColor: "#FFF5DF",
                                // weight:'100px',
                                // width:'100px'
                              })}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "23px",
                                  marginRight: "5px",
                                }}
                                src="/assets/images/Group 1.png"
                                alt="doge"
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="text">
                            <span className="text-muted w-100 d-block" style={{fontSize:'12px'}}>Km</span>
                            <b className="">
                              {(item?.distance / 1000).toFixed(2)}
                            </b>
                          </div>
                        </div>
                        <div className="col-12 my-2 d-flex align-items-center gap-2">
                          <div className="w-50">
                            <CircularProgressbarWithChildren
                              value={item.time != 0 && item.maxTime !=0 ?(item?.time / item?.maxTime) * 100:0}
                              strokeWidth={8}
                              background
                              styles={buildStyles({
                                pathColor: "#8878E7",
                                trailColor: "white",
                                backgroundColor: "#ECE4FB",
                                // weight:'100px',
                                // width:'100px'
                              })}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "23px",
                                  // marginRight: "5px",
                                }}
                                src="/assets/images/Group 2.png"
                                alt="doge"
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="text">
                            <span className="text-muted w-100 d-block" style={{fontSize:'12px'}}>
                              Move min
                            </span>
                            <b className="">
                              {item.time?dateConverter(item.time):'0 '}
                              {/* <Moment format="hh">{item.time}</Moment>
                              <span>h &nbsp;</span>
                              <Moment format="mm">{item.time}</Moment>
                              <span>m</span> */}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="col-12 sectionFour p-3 bg-white mt-4">
          <ul className="list-unstyled p-0 m-0 d-flex justify-content-between">
            <li className=" text-center">
              <div className="imgOuter mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-99.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">{fitnessData.events}</span>
                <span className="title text-muted">Joined Event</span>
              </div>
            </li>
            <li className=" text-center">
              <div className="imgOuter sky mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-100.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">{fitnessData.medals}</span>
                <span className="title text-muted">Medals Won</span>
              </div>
            </li>
            <li className=" text-center">
              <div className="imgOuter lightGreen mx-auto rounded-pill align-items-center d-flex justify-content-center">
                <img className="" src="/assets/images/image-101.png" alt="" />
              </div>
              <div className="points mt-2">
                <span className="d-block value">{fitnessData.clubs}</span>
                <span className="title text-muted">Joined Club</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 sectionFive p-3 bg-white mt-4">
          <div className="row mx-0">
            <div className="col-12 px-0 todayDataHeader pb-2 pb-xl-3 border-bottom d-flex align-items-center justify-content-between ">
              <div className="mainTitle lineHeightMain" style={{color:'#121016'}}>Last Activity</div>
              <div
                // to={{ pathname: "/common-activity", state: { id: "true" } }}
                className="textBtn  text-decoration-none"
                style={{ cursor: "pointer",color:'#EF5036' }}
                onClick={() => navigate("/following-activity")}
              >
                View All
              </div>
            </div>
            <div className="col-12 px-0">
              <div className="activity pt-3">
                {lastActivity.map((el, i) => {
                  return (
                    <div
                      key={Math.random()}
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/following-activity", {
                          state: { id: lastActivity[0]?._id },
                        })
                      }
                    >
                      <div className="col-auto">
                        <div className="imgActivity mx-auto rounded-pill align-items-center d-flex justify-content-center">
                          <img src="/assets/images/image-81.png" alt="" />
                        </div>
                      </div>
                      <div className=" col activityTitle align-items-center d-grid px-0">
                        <span className="d-block">{el?.title}</span>
                        <span className="text-muted d-block">
                          {/* 10 Mar . 10:30 PM . {el.points} pts */}
                          <Moment format="DD MMM">{el.createdAt}</Moment>
                          {" ."}
                          <Moment format="hh:mm a">{el.createdAt}</Moment>
                          {" . "}
                          {/* {" . "}
                          {new Date(el?.createdAt)
                            .toLocaleTimeString("en-US")
                            .slice()}{" "}
                          {" . "} */}
                          {el.points} pts
                        </span>
                      </div>
                      <div className=" col-auto activityStatus">
                        <span className="text-theme1 p-1 px-2 px-xl-3 rounded-pill">
                          {el.activity == 1
                            ? "Running"
                            : "" || el.activity == 2
                            ? "Cycling"
                            : "" || el.activity == 3
                            ? "Jumping"
                            : "" || el.activity == 4
                            ? "Walking"
                            : "" || el.activity == 5
                            ? "Skipping"
                            : ""}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Myactivity data={data} /> */}
    </div>
  );
}

export default LeftSide;
