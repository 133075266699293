import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../Common/Navbar";
import LeftSide from "../../Common/Section/LeftSide";
import { getData, postData } from "../../FetchService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ImageList,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Clubinvited() {
  const location = useLocation();
  console.log(location,"loooocaaati")
  const navigate = useNavigate();
  const [invited, setInvited] = useState([]);
  const [allFriends, setAllFriends] = useState([]);
  const[invitedid,setInvitedId] = useState('')
  const [selectAll, setSelectAll] = useState(false);
  const [loadFriends, setLoadFriends] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [usersId, setUsersId] = useState([]);
  const [modal, setModal] = useState(false);
  console.log("usersId", usersId);
  const getAllFriends = async () => {
    const res = await getData("get-friends");
    setAllFriends(res.data);
    setLoadFriends(true);
  };
  useEffect(() => {
    getAllFriends();
  }, []);
  const closeModal = () => {
    setModal(!modal);
  };

  const selectAllSuggestedFriends = () =>{
    setSelectAll(!selectAll);
    if(!selectAll){
      if(allFriends.length){
        allFriends.forEach(friends =>{
          setUsersId((prev) => {
            return [...prev, friends._id];
          });
        })
      }
    } else{
      setUsersId([]);
    }

  }

  const handleIviteFriendId = (item) => {
    setUsersId((prevIds) => {
      if (prevIds.includes(item._id)) {
        setSelectAll(false);
        return prevIds.filter((existingId) => existingId !== item._id);
      } else {
        return [...prevIds, item._id];
      }
    });
};

  const handleChallange = async () => {
    if(!usersId.length){
      toast.error("You have not selected any friend to challenge your club", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    let data = {
      clubId: location.state?.id._id,
      users: usersId,
    };
    const res = await postData("invite-users-club", data);
    if (res.status == true) {
      setTimeout(() => {
        navigate("/clubs");
      }, [1000]);
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <div
        className={`${openProfile ? "leftPartShow" : ""} col rightPart h-100`}
      >
        <ToastContainer autoClose={1000} />
        <div className="row rightPartInner h-100 flex-column flex-nowrap">
          <Navbar path="Events/Create Event" setOpenProfile={setOpenProfile} />
          <div className="col-12 mainBody flex-fill overflow-hidden">
            <div className="row mainBodyInner h-100">
              <LeftSide />
              {
                loadFriends && (
                  <div className="col-12 col-md-7 col-lg-8 rightBodyPart createEvent flex-fill h-100 overflow-hidden"  style={{backgroundColor:'#F7F7FA'}}>
                <div className="pb-2 row h-100 p-3 p-lg-4 customScroll">
                  <div className="col-12 createEventRight p-3 bg-white">
                    <div className="row mx-0">
                      <div className="col-12 eventHeader pb-2 pb-xl-3 border-bottom fw-semibold">
                        <div className="mainTitle">Invite Challengers</div>
                      </div>
                      <div className="col-12 eventInvited">
                        <div className="row">
                          <div className="col-12 py-2 py-xl-3 scheduleEvent">
                            <div className="row">
                              <div className="col-12">
                                <div className="heading mb-sm-3">
                                  To whom would to like the challenge
                                </div>
                              </div>
                              {
                                allFriends.length ? (
                                  <div className="col-12 mt-3">
                                <div className="row g-3">
                                  <div className="col-12 col-sm-6 col-md-12 col-lg-6 inviteFriendList px-0 px-sm-2 ">
                                    <div className="card bg-white h-100">
                                      <div className="card-header bg-white p-3 ">
                                        <div className="row align-items-center">
                                          <div className="col">
                                            <div className="suggestfriend">
                                              Suggested Friends
                                            </div>
                                          </div>
                                          <div className="col-auto">
                                            <input
                                              className="form-check-input shadow-none align-items-center d-flex justify-content-center mt-0 me-1 me-sm-2 rounded-pill"
                                              type="checkbox"
                                              value=""
                                              id=""
                                              checked={selectAll}
                                              onChange={() =>{selectAllSuggestedFriends()}}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body p-3 ">
                                        {allFriends.map((el) => {
                                          return (
                                            <div
                                              className="row align-items-center mb-3 detail "
                                              key={Math.random()}
                                            >
                                              <div className="col-auto">
                                                <div className="image">
                                                  <img
                                                    style={{
                                                      height: "30px",
                                                      width: "30px",
                                                      borderRadius: "50%",
                                                    }}
                                                    // src="/assets/images/image-148.png"
                                                    src={el.image}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="col px-0">
                                                <div className="  name">
                                                  {`${el.firstName} ${el.lastName}`}
                                                </div>
                                              </div>
                                              <div className="col-auto">
                                                <input
                                                  className="form-check-input shadow-none align-items-center d-flex justify-content-center mt-0 me-1 me-sm-2 rounded-pill"
                                                  type="checkbox"
                                                  onChange={() =>{
                                                    handleIviteFriendId(el)
                                                  }}
                                                  value={el._id}
                                                  id=""
                                                  checked={
                                                    usersId?.filter(
                                                      (item) =>
                                                        item == el._id
                                                    ).length
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                ):(
                                  <div className="col-12 mt-3">
                                <div className="row g-3">
                                  <div className="col-12 col-sm-6 col-md-12 col-lg-6 inviteFriendList px-0 px-sm-2 ">
                                    <div className="suggestfriend">
                                      You don't have any friends to challenge this club
                                    </div>
                                  </div>
                                </div>
                              </div>

                                )
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 pt-3 pt-xl-4">
                        <div className="row">
                          <div className="col-12 d-flex gap-3">
                          { allFriends.length ? (
                            <button
                              type="button"
                              onClick={() => handleChallange()}
                              className="btn btnBold btn-theme2 p-1 p-sm-2 px-2 px-sm-3 px-lg-4"
                            >
                              <i>Challenge</i>
                            </button> ): null}
                            <NavLink to="/clubs/createclub">
                              <button
                                type="button"
                                className="btn textBtn text-dark border-0 p-0"
                              >
                                Cancel
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                )
              }

            </div>
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        onClose={closeModal}
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.3)",
          padding: "10px",
          zIndex: 100000000,
        }}
      >
        <Paper
          sx={{
            padding: "10px",
          }}
        >
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Cannot Choose more than 5 friends
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </Typography> */}
            <Button onClick={closeModal}>Close</Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default Clubinvited;
