import { SETUSERS } from "./Const";
import { LOGOUT } from "./Const";
export const setUsers = (payload) => {
  return {
    type: SETUSERS,
    payload: payload,
  };
};
export const setLatLong = (payload) => {
  return {
    type: "LATLONG",
    payload: payload,
  };
};

export const setmini = () => {
  return {
    type: "MINISIDE",
  };
};
export const minisearchbar = () => {
  return {
    type: "MINISEARCHBAR",
  };
};
export const  changeUser  = (payload) => {
  return {
    type: "CHANGE_USER",
    payload: payload
  };
};
export const  chatID  = (payload) => {
  return {
    type: "CHAT_Id",
    payload: payload
  };
};
export const  addUnit  = (payload) => {
  return {
    type: "ADD_UNIT",
    payload: payload
  };
};
export const  clubdetail  = (payload) => {
  return {
    type: "CLUB_DETAIL",
    payload: payload
  };
};
//  export const setUsers = (payload) => {
//         return ({
//             type: SETUSERS,
//             payload: payload
//         })

//         }