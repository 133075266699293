import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getData } from "../../../FetchService";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";

import Map from "./Map1";
import "react-calendar/dist/Calendar.css";
import Moment from "react-moment";
import { likeactivity } from "./ActivityServices";
import { addcomment } from "./ActivityServices";
import { useRef } from "react";
import './style.css';
const googleMapsApiKey = process.env.REACT_APP_API_KEY;

function ActivityModal(props) {
  const {
    setactive,
    setModalOpen,
    setCheck,
    active,
    loadingElement,
    containerElement,
    reference,
    mapElement,
    activeActivityId,
    MonthlyActivity,
  } = props;

  const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "Mobile"
      : "Desktop";

  let updatedMonthActivity = MonthlyActivity;

  const [writecomment, setwritecomment] = useState(false);
  let coords;
  const commentScroll = useRef(null);
  const [comment, setcomment] = useState("");
  const [oneactivity, setoneactivity] = useState([]);
  const getoneactivity = async (activeActivityId) => {
    const path = `activity?id=${activeActivityId?._id}`;
    const res = await getData(path);
    setoneactivity(res.data);
  };
  useEffect(() => {
    getoneactivity(activeActivityId);
    handleCo();
  }, [activeActivityId]);
  const handleCo = (data) => {
    let a = [];
    data?.map((el) => {
      a.push({ latitude: el[1], longitude: el[0] });
    });
    return a;
  };

  const handlecomment = async (value) => {
    const res = await addcomment(value, comment);
    if (res.status == true) {
      setcomment("");
      getoneactivity(activeActivityId);
      setwritecomment(false);
      updatedMonthActivity();
    }
  };
  const handleLike = async (isLiked, id) => {
    const res = await likeactivity(isLiked, id);
    if (res.status == true) {
      getoneactivity(activeActivityId);
      updatedMonthActivity();
    }
  };

  useEffect(() => {
    if (commentScroll.current) {
      commentScroll.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comment]);

  return (
    <div
      className={`modal fade viewPostModal ${
        active == "comments" ? "show" : ""
      }`}
      style={{ display: active == "comments" ? "block" : "none" }}
      id="viewActivityPostModal"
      tabIndex="-1"
      role={active == "comments" ? "dialog" : ""}
      aria-labelledby="viewActivityPostLabel"
      aria-hidden={active == "comments" ? "" : "true"}
      aria-modal={active == "comments" ? "true" : ""}
    >
      {oneactivity.map((item) => {
        coords = handleCo(item?.coordinates);
        return (
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            ref={reference}
            key={item._id}
          >
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="row g-0">
                  <div className="text-end pe-3" onClick={() => setactive("")}>
                    &#x2715;
                  </div>
                  <div className="col-12 col-lg-6 d-flex  align-items-center justify-content-center">
                    {item.activity == 3 || item.activity == 5 ? (
                      <div className="col-lg-6 card">
                        <div className="card-body">
                          <h5 className="card-title">
                            {item.activity == 3 ? "Total jumps" : "Total steps"}
                          </h5>
                          <div className="cal-12 d-flex align-items-center justify-content-center pt-4 pb-4 ">
                            <h6 className="cal-4 card-subtitle mb-2 text-muted">
                              {item.activity == 3
                                ? item.jumps + " " + "jumps"
                                : item.steps + " " + "steps"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="maps_card">
                          <Map
                            googleMapURL={
                              "https://maps.googleapis.com/maps/api/js?key=" +
                              googleMapsApiKey +
                              "&libraries=geometry,drawing,places"
                            }
                            markers={coords}
                            loadingElement={
                              loadingElement || (
                                <div
                                  style={{
                                    height:
                                      detectDeviceType() == "Mobile"
                                        ? `100%`
                                        : `100%`,
                                  }}
                                />
                              )
                            }
                            containerElement={
                              containerElement || (
                                <div
                                  style={{
                                    height:
                                      detectDeviceType() == "Mobile"
                                        ? "40vh"
                                        : "194px",
                                  }}
                                />
                              )
                            }
                            mapElement={
                              mapElement || (
                                <div
                                  style={{
                                    height:
                                      detectDeviceType() == "Mobile"
                                        ? "100%"
                                        : `100%`,
                                  }}
                                />
                              )
                            }
                            center={{
                              lat: coords[0].latitude,
                              lng: coords[0].longitude,
                            }}
                            zoom={9}
                          />
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="row mx-0 card_box_profile">
                      <div className="col-12">
                        <div className="row bg-white p-3  -lg-3 align-items-center justify-content-between">
                          <div className="col-auto px-0">
                            <div className="d-flex align-items-center user_name_image">
                              <img
                                src={item.user.image}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50px",
                                }}
                                alt="image-11"
                              />
                              <div className="profile_user_text">
                                <div className="user_name mb-sm-2 ms-2">
                                  {" "}
                                  {item.user.firstName} {item.user.lastName}
                                </div>
                                <div className="time_and_Date ms-2">
                                  <Moment format="DD MMMM YYYY, HH:MM A ">
                                    {item.createdAt}
                                  </Moment>{" "}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 text_Message mb-2 px-0">
                            <div className="fw-bold my-2 contentTitle">
                              {item.title}
                            </div>
                            <p className="summery_text mt-2">
                              {item.description}
                            </p>
                          </div>
                          <div className="col-12 clc_time_km mb-3 px-0">
                            <div className="d-flex align-items-center gap-2 gap-md-4">
                              <div className="d-flex gap-md-2 gap-1 align-items-center">
                                <img
                                  className="image_ic1"
                                  src="../assets/images/image-124.png"
                                  alt="clock_icon"
                                />
                                <span className="icon_text">
                                  
                                    {item.time} hr
                                  
                                </span>
                              </div>
                              <div className="d-flex gap-md-2 gap-1 align-items-center">
                                <img
                                  className="image_ic2"
                                  src="../assets/images/image-125.png"
                                  alt="clock_icon"
                                />
                                <span className="icon_text">
                                  {(item.distance / 1000).toFixed(2)}
                                  km
                                </span>
                              </div>
                              <div className="d-flex gap-md-2 gap-1 align-items-center">
                                <img
                                  className="image_ic3"
                                  src="../assets/images/image-126.png"
                                  alt="clock_icon"
                                />
                                <span className="icon_text">
                                  {item.calories} cal
                                  
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-12 px-0 mb-3 fontSize ${
                              item.allcomments.length > 3
                                ? "rounded"
                                : ""
                            }`}
                            style={{
                              maxHeight: item.comments?'200px':'',
                              overflowX:item.comments? "hidden":'',
                              overflowY: item.comments?"auto":'',
                              marginBottom: "20px",
                            }}
                          >
                            {item.allcomments.map((item) => {
                              return (
                                <div
                                  className="row g-2 mb-3 ps-2 pe-2 d-flex  "
                                  key={item._id}
                                  ref={commentScroll}
                                >
                                  <div className="col-auto">
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                      }}
                                      className="border border-theme1 rounded-circle overflow-hidden commentImage"
                                    >
                                      <img
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "50%",
                                        }}
                                        src={item.user.image}
                                        alt="image-75"
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div>
                                      <span className="fw-bold me-2">
                                        {item.user.firstName}{" "}
                                        {item.user.lastName}
                                        {"  "}
                                      </span>
                                      {item.comment}
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="updateStatus">
                                      <Moment format="hh:mm ">
                                        {item.createdAt}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="col-12 mb-md-2 like_com_shr px-0">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center gap-md-2 gap-1">
                                {item.isLiked ? (
                                  <AiFillHeart
                                    style={{ color: "red", fontSize: "30px" }}
                                    onClick={() =>
                                      handleLike(item.isLiked, item._id)
                                    }
                                  />
                                ) : (
                                  <AiOutlineHeart
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                      fontSize: "30px",
                                    }}
                                    onClick={() =>
                                      handleLike(item.isLiked, item._id)
                                    }
                                  />
                                )}

                                <div className="wid"  style={{marginRight:'5px'}}>
                                  <img
                                    src="../assets/images/image-121.png"
                                    alt="Comment_icon"
                                   
                                    onClick={() =>
                                      setwritecomment((prevState) => !prevState)
                                    }
                                  />
                                </div>
                                <NavLink className="wid">
                                  <img
                                    src="../assets/images/image-122.png"
                                    alt="Share_icon"
                                  />
                                </NavLink>
                              </div>
                              <div className="view_Button ">
                                <div className="text-decoration-none ">
                                  <button
                                    className="btn btn-white text-theme1 view_Button_a p-0 d-flex align-items-center border-0 "
                                    onClick={() => {
                                      setModalOpen("modal");
                                      setactive();
                                      setCheck(true);
                                    }}
                                  >
                                    View analysis{" "}
                                    <span>
                                      <img
                                        className="ms-1"
                                        src="../assets/images/image-226.png"
                                        alt=""
                                      />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="liketext mt-lg-1">
                            {item?.likes>1?`${item.likes} likes`:item.likes == 1?"1 like":''} 
                            </div>
                          </div>
                          <hr></hr>
                          <div className="col-12 mt-3 bottam_user_image px-0">
                            {writecomment == true ? (
                              <div className="row mx-0">
                                <div className="col-auto px-0 me-md-2 d-flex align-items-center">
                                  <div className="comment_image_user me-1">
                                    <img
                                      src={
                                        item.user.image
                                          ? item.user.image
                                          : "./assets/images/image-123.png"
                                      }
                                      alt="usr_image"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col px-0 position-relative">
                                  <textarea
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setcomment(e.target.value)}
                                    onKeyPress={(e) => {
                                      if (e.code == "Enter") {
                                        handlecomment(item._id, e);
                                      }
                                    }}
                                    style={{padding:'11px'}}
                                    className="form-control shadow-none border-muted form_text  form_com"
                                    id="comment1"
                                    placeholder="Write comment..."
                                  />
                                  <button
                                    className="position-absolute border-0  p-0"
                                    onClick={(e) => handlecomment(item._id, e)}
                                  >
                                    <img
                                      className="com_img w-100 "
                                      src="/assets/images/image-117.png"
                                      alt="share_icon"
                                    />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ActivityModal;
