import { useSelector } from "react-redux";
import { SETUSERS } from "./Const";

const initial_state = {
  userData: [],
 club:null,
  latLong: "",
  loading: false,
  miniSide: false,
  miniSearchBar: false,
  chatId: "null",
  user: {},
  unit: {
    height: '',
    weight: '',
    distance: ''
  }
};


const Reducers = (state = initial_state, action) => {


  switch (action.type) {
    case SETUSERS:
      return { ...state, userData: action.payload };
    case "LATLONG":
      return { ...state, latLong: action.payload };
    case "MINISIDE":
      return { ...state, miniSide: !state.miniSide };
    case "MINISEARCHBAR":
      return { ...state, miniSearchBar: !state.miniSearchBar };
    case "CHANGE_USER":
      return {
        ...state, user: action.payload,
      };
    case "CHAT_Id":
      return {
        ...state, chatId: action.payload
      };
    case "ADD_UNIT":
      return {
        ...state, unit: action.payload
      };
      case "CLUB_DETAIL":
      return{
        ...state,club:action.payload
      }
    default:
      return state;
  }
};
export default Reducers;
