import { putData } from "../../../FetchService";
import { postData } from "../../../FetchService";

export const likeactivity = async (condition, value) => {
  const body = {
    id: value,
  };
  if (!condition) {
    const res = await putData("add-like-activity", body);

    return res;
  } else {
    const res = await putData("remove-like-activity", body);
    return res;
  }
};
export const addcomment = async (value, comment) => {
  const body = {
    postId: value,
    comment: comment,
  };

  const res = await postData("add-comment-activity", body);
  return res;
};
