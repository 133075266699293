import React from "react";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { getData, postData, putData } from "../../../FetchService";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import Calendar from "react-calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Geocode from "react-geocode";

import { MARKER } from "react-google-maps/lib/constants";
import GoogleMapReact from "google-map-react";
import Polyline from "react-google-maps/lib/components/Polyline";
import moment from "moment";
import Map from "./Map1";
import "react-calendar/dist/Calendar.css";

import Moment from "react-moment";
import ActivityModal from "./ActivityModal";
import { likeactivity } from "./ActivityServices";
import { addcomment } from "./ActivityServices";
import useClickOutside from "../../../customHooks/useClickOutside";
import ChartModal from "./ChartModal";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import './style.css'
function Myactivity(props) {
  const [activity, setactivity] = useState([]);
  const [commentId, setCommentId] = useState("");

  const googleMapsApiKey = process.env.REACT_APP_API_KEY;
  const [date, setdate] = useState(new Date());
  const [activitydata, setactivitydata] = useState([]);

  const [chartmodal, setChartModal] = useState("");
  const [editactivity, seteditactivity] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [filterEvents, setfilterEvents] = useState("1");
  const [todayActvity, setTodayActivity] = useState([]);
  const [filterActivity, setfilterActivity] = useState([]);
  const [editActivity, setEditActivity] = useState({});

  const month = moment(date).format("MM");
  const year = moment(date).format("YYYY");
  const [open, setOpen] = useState("");
  const [comment, setComment] = useState("");
  const [active, setactive] = useState("");
  const [modal, setModal] = useState("");
  const [check, setCheck] = useState(false);
  const [activeActivityId, setActiveActivityId] = useState(null);
  const [modalopen, setModalOpen] = useState("");
  const [activityreportid, setReportActivityId] = useState("");
  const Data = useSelector((state) => state.userData);

  const modalRef = useRef(null);
  let coords;

  const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "Mobile"
      : "Desktop";

  const activitytemplate = async () => {
    const res = await getData("activity-templates");
    setactivity(res);
  };
  useEffect(() => {
    activitytemplate();
  }, []);
  const ondateChange = (e) => {
    monthactivity();
    filterTodayActivities();
    setdate(e);
  };

  const selectedDateActivity = () => {
    let a = activitydata?.filter((el) => {
      if (
        `${new Date(el.createdAt).getDate()} ${new Date(
          el.createdAt
        ).getMonth()} ${new Date(el.createdAt).getFullYear()}` ==
        `${new Date(date).getDate()} ${new Date(date).getMonth()} ${new Date(
          date
        ).getFullYear()}`
      ) {
        return el;
      }
    });
    setTodayActivity(a);
  };

  useEffect(() => {
    selectedDateActivity();
  }, [date, activitydata]);

  const monthactivity = async () => {
    const path = `month-activities?month=${month}&year=${year}`;
    const res = await getData(path);
    setactivitydata(res.data);
  };

  useEffect(() => {
    monthactivity();
  }, [month, year]);

  const filterTodayActivities = () => {
    let a = activitydata.filter((el) => {
      if (new Date(el.createdAt).getDate() == new Date().getDate()) {
        return el;
      }
    });
    setTodayActivity(a);
  };

  const handleLikePost = async (isLiked, id) => {
    const res = await likeactivity(isLiked, id);
    if (res.status) {
      monthactivity();
    }
  };

  const uploadComment = async (e, id) => {
    e.preventDefault();
    const data = {
      postId: id,
      comment: comment,
    };
    if (comment) {
      const res = await putData("add-comment-post", data);
      if (res.status == true) {
        setComment("");
        setCommentId("");
        monthactivity();
      }
    } else {
      toast.error("Comment cannot be empty !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const dateConverter = (time) => {
    var date = new Date(null);
    date.setSeconds(time);

    var hhmmssFormat = date.toISOString().substr(11, 8);

    let hr = hhmmssFormat.split(":")[0];
    let min = hhmmssFormat.split(":")[1];
    let sec = hhmmssFormat.split(":")[2];

    return `${hr != "00" ? hr + "hr" : ""} ${min != "00" ? min + "min" : ""} ${
      sec != "00" ? sec + "sec" : ""
    }`;
  };

  const [place, setplace] = useState([]);

  const clickHandler = () => {
    setactive("");
    setChartModal("");

  };
  useClickOutside(modalRef, clickHandler);

  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom,
  } = props;

  const handleCo = (data) => {
    let a = [];
    data?.map((el) => {
      a.push({ latitude: el[1], longitude: el[0] });
    });

    return a;
  };

  let aa = [];
  const arr = activitydata?.filter((el) => {
    aa.push(
      `${new Date(el.createdAt).getDate()} ${new Date(el.createdAt).getMonth()}`
    );
  });

  const handlefilterActivity = () => {
    let fill = todayActvity?.filter((el) => {
      return el.activity == filterEvents;
    });
    setfilterActivity(fill);
  };

  useEffect(() => {
    handlefilterActivity();
  }, [todayActvity, filterEvents]);

  const showHideComment = (id) => {
    if (commentId == id) {
      setCommentId("");
    } else {
      setCommentId(id);
    }
  };

  const activityModalOpen = (el) => {
    setActiveActivityId(el);
    setactive("comments");
    setplace(coords);
  };

  const handleViewAnalysis = (el) => {
    setModalOpen("modal");
    setActiveActivityId(el);
    setCheck(false);
    setplace(coords);
  };

  const DeleteActivity = (id) => {
    const body = {
      id: id,
    };
    const res = putData("delete-activity", body);
    monthactivity();
    seteditactivity("");
  };

  const EditActivity = (value) => {
    const body = {
      id: value.id,
      title: value.title,
      description: value.description,
    };
    const res = putData("edit-activity", body);
    monthactivity();
    seteditactivity("");
  };

  const handleEditActivity = (e) => {
    const { name, value } = e.target;
    setEditActivity({ ...editActivity, [name]: value });
  };

  const handlePrevious = (e) => {
    monthactivity();
    filterTodayActivities();
    setdate(e);
  };
  console.log(detectDeviceType());
  return (
    <div
      className={`tab-pane fade ${
        props.data == "My Activity Log" ? "active show" : " "
      }`}
      id="myActvityLog"
      role="tabpanel"
      aria-labelledby="myActvityLog-tab"
      tabIndex="0"
    >
      <ToastContainer autoClose={1000} />
      <div className="row followinglayout  h-100 gap-4 position-relative" >
        <div className="col-12 filters" >
          <div className="row mx-0">
            <div className="col-12 col-sm-auto px-0">
              <ul className="col-12 p-0 m-0 d-flex flex-wrap todayDataHeader list-unstyled d-flex  justify-content-between align-items-center">
                <li>
                  <div className="mainTitle">Filters</div>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setfilterEvents("1");
                      // setdate('')
                    }}
                    className="btn textBtn text-theme1 border-0 shadow-none p-0"
                  >
                    Clear Filters
                  </button>
                </li>
              </ul>
              <ul className="col-12 p-0 m-0 d-flex flex-wrap mt-3 list-unstyled gap-2 overflow-auto">
                {activity?.data?.map((item) => (
                  <li className="d-inline-block mt-1 mt-sm-0" key={item._id}>
                    <input
                      type="radio"
                      className="btn-check d-none"
                      id={item._id}
                      value={item.ref}
                      autoComplete="off"
                      onChange={(e) => setfilterEvents(e.target.value)}
                      checked={filterEvents == item.ref}
                    />
                    <label
                      className="btn btnBorderd btn-outline-theme1 rounded-pill"
                      htmlFor={item._id}

                    >
                      {item?.activity}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 bg-white p-md-4 p-3 card_box_sh cursor" style={{display:'flex'}}>
          <span className="clacactive MainCardCalendar"
            // style={{
            //   position: 'absolute',
            //   zIndex: '100',
            //   left: '58%',
            //   marginTop: '11px',
            //   backgroundColor:'#fde5e1',
            //   borderRadius: '30px',
            //   width: '92px',
            //   height: '29px',
            //   color:'#EF5036',
            //   paddingLeft:'11px',
            //   paddingTop:'3px',
            //   fontSize:'14px',
            //   marginLeft:'34px'
            // }}
          >
           {`${filterActivity.length} Activities`}
          </span>
          <Calendar
            onChange={ondateChange}
            value={date}
            tileClassName={({ date }) => {
              if (aa.includes(`${date.getDate()} ${date.getMonth()}`)) {
                return "highlight_button";
              }
            }}
            onClickMonth={handlePrevious}
            onClickYear={handlePrevious}
          />
        </div>
        <div className="text-decoration-none text-black mt-2">
          {filterActivity.length ? (
            filterActivity?.map((item) => {
              coords = handleCo(item?.coordinates);
              return (
                <div
                  className="col-12 bg-white p-md-4 p-3 card_box_sh cusser mb-2 "
                  key={item._id}
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <div className="row mx-0 card_box_profile">
                    <div className="col-12">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-auto px-0">
                          <div className="d-flex align-items-center user_name_image">
                            <div className="custom_user_image">
                              <img
                                src={
                                  item.user.image
                                    ? item.user.image
                                    : "/assets/images/image-119.png"
                                }
                                alt="Profile_pic"
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "53px",
                                  marginTop: "-9px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                            <div
                              className="profile_user_text mb-2 "
                              style={{ marginLeft: "11px" }}
                            >
                              <div className="user_name mb-sm-2 ms-2">
                                {item.user.firstName} {item.user.lastName}
                              </div>
                              <div
                                className="time_and_Date ms-2"
                                style={{ marginLeft: "11px" }}
                              >
                                <Moment format="DD MMMM YYYY , HH:MM A">
                                  {item.createdAt}
                                </Moment>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col icon_dropy px-0">
                          <div className="dropdown box_drop text-end">
                            <span
                              className={`dropdown-toggle toggle_Drop ${
                                chartmodal == "editdelete" ? "show" : ""
                              }`}
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded={
                                chartmodal == "editdelete" ? true : false
                              }
                              type="button"
                              onClick={() => {
                                setChartModal("editdelete");
                                setReportActivityId(item._id);
                              }}
                            >
                              <img
                                src="/assets/images/image-210.png"
                                alt="icon_image"
                                style={{
                                  marginLeft: "1px",
                                  marginRight: "4px",
                                }}
                              />
                            </span>
                            <ul
                              className={`dropdown-menu border-0 ${
                                chartmodal == "editdelete" ? "show" : ""
                              }`}
                              aria-labelledby="dropdownMenuButton1"
                              style={{
                                position:
                                  chartmodal == "editdelete" ? "absolute" : "",
                                inset:
                                  chartmodal == "editdelete"
                                    ? "0px 0px auto auto"
                                    : "",
                                margin: chartmodal == "editdelete" ? "0px" : "",
                                transform:
                                  chartmodal == "editdelete"
                                    ? "translate3d(0.8px, 38.4px, 0px)"
                                    : "",
                              }}
                              data-popper-placement={
                                chartmodal == "editdelete" ? "bottom-end" : ""
                              }
                            >
                              {activityreportid == item._id ? (
                                item.user._id == Data._id ? (
                                  <div ref={modalRef}>
                                    <li className="dropdown-item p-0 py-1">
                                      <button
                                        type="button"
                                        className="btn w-100 border-0 text-dark bg-transparent d-flex justify-content-start dorop_item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editPostModal"
                                        onClick={() => {
                                          setEditActivity({
                                            title: item.title,
                                            description: item.description,
                                            id: item._id,
                                          });

                                          seteditactivity("edit");
                                        }}
                                      >
                                        <img
                                          src="/assets/images/image-131.png"
                                          alt="image-131"
                                          className="me-3 imageicon"
                                        />
                                        Edit Activity
                                      </button>
                                    </li>
                                    <li className="dropdown-item p-0 py-1">
                                      <button
                                        type="button"
                                        data-bs-toggle="modal"
                                        className="btn w-100 border-0 text-dark bg-transparent d-flex justify-content-start dorop_item"
                                        onClick={() => {
                                          setDeleteId(item._id);
                                          seteditactivity("delete");
                                        }}
                                        src="/assets/images/delete-image.png"
                                        data-bs-target="#askdeletecategory"
                                      >
                                        <img
                                          src="/assets/images/delete-image.png"
                                          alt="delete-image"
                                          className="me-3 imageicon"
                                        />
                                        Delete Activity
                                      </button>
                                    </li>
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="col-12 text_Message mb-2 px-0">
                          <div
                            className="morning_walk_time"
                            style={{ marginLeft: "11px" }}
                          >
                            {item.title}
                          </div>
                          <div
                            className="summery_text"
                            style={{ marginLeft: "11px" }}
                          >
                            {open
                              ? item?.description
                              : item?.description.slice(0, 120)}

                            <button
                              className="view_com px-0 border-0 bg-white text-blue"
                              onClick={() => setOpen((prevState) => !prevState)}
                            >
                              {open ? (
                                <p style={{ color: "red" }}>...less</p>
                              ) : item.description.length > 120 ? (
                                <p style={{ color: "green" }}>...more</p>
                              ) : (
                                ""
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="col-12 clc_time_km mb-3 px-0">
                          <div className="d-flex align-items-center gap-md-4">
                            <div className="d-flex gap-md-2 gap-1 align-items-center">
                              <img
                                className="image_ic1"
                                src="/assets/images/image-124.png"
                                alt="clock_icon"
                                style={{ marginLeft: "11px" }}
                              />
                              <span className="icon_text">
                              {dateConverter(item.time)}
                              </span>
                            </div>
                            <div className="d-flex gap-md-2 gap-1 align-items-center">
                              <img
                                className="image_ic2"
                                src="/assets/images/image-125.png"
                                alt="clock_icon"
                              />
                              <span className="icon_text">
                                {(item.distance / 1000).toFixed(2)} km
                              </span>
                            </div>
                            <div className="d-flex gap-md-2 gap-1 align-items-center">
                              <img
                                className="image_ic3"
                                src="/assets/images/image-126.png"
                                alt="clock_icon"
                              />
                              <span className="icon_text">
                                {item.calories} cal
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 map mb-2 px-1">
                                      {item.activity == 3 || item.activity == 5 ? (

                                        <div className="card jumpcard">
                                          <div className="card-body d-flex align-items-center justify-content-between">
                                              <h5 className="card-title m-0">
                                                {item.activity == 3
                                                ? "Total jumps"
                                                : "Total steps"}
                                                </h5>
                                              <h6 className="card-subtitle m-0 fw-bold text-theme1 text-center">
                                              {item.activity == 3
                                                  ? item.jumps + " " + "Jumps"
                                                  : item.steps + " " + "steps"}
                                              </h6>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="maps_card mb-2"
                                          style={{ marginLeft:'-6px' }}
                                        >
                                          <Map
                                            googleMapURL={
                                              "https://maps.googleapis.com/maps/api/js?key=" +
                                              googleMapsApiKey +
                                              "&libraries=geometry,drawing,places"
                                            }
                                            markers={coords}
                                            loadingElement={
                                              loadingElement || (
                                                <div
                                                  style={{
                                                    height:
                                                      detectDeviceType() ==
                                                      "Mobile"
                                                        ? `100%`
                                                        : `100%`,
                                                  }}
                                                />
                                              )
                                            }
                                            containerElement={
                                              containerElement || (
                                                <div
                                                  style={{
                                                    height:
                                                      detectDeviceType() ==
                                                      "Mobile"
                                                        ? "40vh"
                                                        : "194px",
                                                  }}
                                                />
                                              )
                                            }
                                            mapElement={
                                              mapElement || (
                                                <div
                                                  style={{
                                                    height:
                                                      detectDeviceType() ==
                                                      "Mobile"
                                                        ? "100%"
                                                        : `100%`,
                                                        borderRadius: '24px'
                                                  }}
                                                />
                                              )
                                            }
                                            center={{
                                              lat: coords[0].latitude,
                                              lng: coords[0].longitude,
                                            }}
                                            zoom={9}
                                          />
                                        </div>
                                      )}
                                    </div>
                        <div className="col-12 mb-md-2 like_com_shr px-0">
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className="d-flex align-items-center gap-md-2 gap-1"
                              style={{ marginLeft: "11px" }}
                            >
                              {item.isLiked ? (
                                <AiFillHeart
                                  style={{
                                    color: "red",
                                    fontSize: "30px",
                                  }}
                                  onClick={() =>
                                    handleLikePost(item.isLiked, item._id)
                                  }
                                />
                              ) : (
                                <AiOutlineHeart
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                  onClick={() =>
                                    handleLikePost(item.isLiked, item._id)
                                  }
                                />
                              )}

                              <div
                                className="ms-1 wid"
                                onClick={() => showHideComment(item._id)}
                              >
                                {/* <img
                                style={{height:"20px"}}
                                  src="/assets/images/image-121.png"
                                  alt="Comment_icon"
                                /> */}
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 512 512"
                                  height="1,5em"
                                  width="1.5em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path>
                                </svg>
                              </div>
                              <div className="ms-1 wid" to="">
                                {/* <img
                                  src="/assets/images/image-122.png"
                                  alt="Share_icon"
                                /> */}
                                {/* <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 16 16"
                                  height="1.5em"
                                  width="1.5em"
                                  xmlns="http://www.w3.org/2000/svg"
                                > */}
                                <img
                                  src="/assets/images/Send.png"
                                  style={{ marginLeft: "1px" }}
                                />
                                {/* </svg> */}
                              </div>
                            </div>

                            <div className="view_Button ">
                              <div className="text-decoration-none ">
                                <button
                                  className="btn btn-white text-theme1 view_Button_a p-0 d-flex align-items-center border-0 "
                                  onClick={() => {
                                    handleViewAnalysis(item);
                                    setModal(true);
                                  }}
                                  style={{marginRight:'17px'}}
                                >
                                  View analysis
                                  <span>
                                    <img
                                      className="ms-1"
                                      src="../assets/images/image-226.png"
                                      alt=""
                                    />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="liketext mt-lg-1"
                            style={{ marginLeft: "11px" }}
                          >
                            {item?.likes>1?`${item.likes} likes`:item.likes == 1?"1 like":''}
                          </div>
                        </div>
                        <div className="col-12 comment_all border-bottom pb-3 px-0">
                        { item.comments >1? <div className="view_all_comments">
                            <div className="text-decoration-none ">
                              <button
                                className="view_com px-0 border-0 bg-white"
                                onClick={() => {
                                  activityModalOpen(item);
                                  setModal(true);
                                }}
                                style={{ marginLeft: "11px" }}
                              >
                               { `View all ${item.comments?item.comments:''} comments` }
                              </button>
                            </div>
                          </div>:''}
                          <div className="show_comment d-flex alignItems-flex-start " style={{width:"70%", height:"20px"}}>
                            <div
                              className="comment_user_nam "
                              style={{ marginLeft: "11px"}}
                            >
                              {item?.lastComment?.user
                                ? <div style={{display:"flex"}}><div>{item?.lastComment?.user?.firstName}</div><div style={{marginLeft:"3px"}}>{item?.lastComment?.user?.lastName[0]}.</div></div>
                                : item?.allcomments
                                ? `${
                                    item?.allcomments[
                                      item?.allcomments.length - 1
                                    ]?.user?.firstName
                                  } ${
                                    item?.allcomments[
                                      item?.allcomments.length - 1
                                    ]?.user?.lastName
                                  } `
                                : ""}
                            </div>
                            <div
                              style={{
                                width: "80%",
                                marginTop: "-3px",
                                marginLeft:"8px",
                              }}
                            >
                              {item?.lastComment?.comment
                                ? item?.lastComment?.comment.length > 42 ? item?.lastComment?.comment.slice(0,40)+"..." : item?.lastComment?.comment
                                : item?.allcomments
                                ? item?.allcomments[
                                    item?.allcomments.length - 1
                                  ]?.comment
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-3 bottam_user_image px-0">
                          {commentId == item._id ? (
                            <div className="row mx-0">
                              <div className="col-auto px-0 me-md-2 d-flex align-items-center">
                                <div className="comment_image_user me-1" style={{marginLeft:'11px'}}>
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                    src={Data.image}
                                    alt="usr_image"
                                  />
                                </div>
                              </div>
                              <div className="col px-0 position-relative">
                                <textarea
                                  type="text"
                                  className="form-control shadow-none  border-muted form_text  form_com"
                                  id="comment1"
                                  style={{padding:'11px'}}
                                  placeholder="Write comment..."
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.key == "Enter") {
                                      uploadComment(e, item._id);
                                    }
                                  }}
                                />
                                <button
                                  onClick={(e) => uploadComment(e, item._id)}
                                  className="position-absolute border-0  p-0"
                                >
                                  <img
                                    className="com_img w-100 "
                                    src="/assets/images/image-117.png"
                                    alt="share_icon"
                                  />
                                </button>
                              </div>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="
                    row
                    p-3
                     card_box_sh
                     bg-white
                     rounded-4
                     d-flex
                      align-items-center
                      text-danger
                       justify-content-center"
            >
              No data found !
            </div>
          )}
        </div>
      </div>
      {/* modals......... */}
      <div
        className={`modal fade ${editactivity == "delete" ? "show" : ""} `}
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={editactivity == "delete" ? "" : true}
        aria-modal={editactivity == "delete" ? true : ""}
        role={editactivity == "delete" ? "dialog" : ""}
        style={{ display: editactivity == "delete" ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
                style={{ color: "#EF5036" }}
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete the Activity?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  DeleteActivity(deleteId);
                  seteditactivity("");
                }}
                style={{ backgroundColor: "#EF5036",width:'100px',borderRadius:'15px' }}
              >
                Delete
              </button>{" "}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => seteditactivity("")}
                style={{ backgroundColor: "#27194A",width:'100px',borderRadius:'15px' }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${editactivity == "edit" ? "show" : ""} `}
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={editactivity == "edit" ? "" : true}
        aria-modal={editactivity == "edit" ? true : ""}
        role={editactivity == "edit" ? "dialog" : ""}
        style={{ display: editactivity == "edit" ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">Do you want to Edit the Activity?</div>
            <form>
              <div className="mb-3 ms-3 me-2">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={editActivity.title}
                  placeholder="Title"
                  className="form-control"
                  id="title"
                  aria-describedby="title"
                  onChange={(e) => handleEditActivity(e)}
                />
              </div>
              <label className="ms-3 me-2" htmlFor="floatingTextarea">
                Description
              </label>
              <div className="form-floating ms-3 me-2">
                <textarea
                  className="form-control"
                  placeholder="description"
                  name="description"
                  value={editActivity.description}
                  id="floatingTextarea"
                  onChange={(e) => handleEditActivity(e)}
                ></textarea>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  EditActivity(editActivity);
                  seteditactivity("");
                }}
                style={{ backgroundColor: "#EF5036",width:'100px',borderRadius:'15px' }}
              >
                Edit
              </button>{" "}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => seteditactivity("")}
                style={{ backgroundColor: "#27194A",width:'100px',borderRadius:'15px' }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {modal ? (
        <ActivityModal
          active={active}
          setModalOpen={setModalOpen}
          setactive={setactive}
          reference={modalRef}
          {...props}
          MonthlyActivity={monthactivity}
          activeActivityId={activeActivityId}
          check={check}
          setCheck={setCheck}
        />
      ) : (
        ""
      )}
      {modal ? (
        <ChartModal
          modalopen={modalopen}
          activeActivityId={activeActivityId}
          setModalOpen={setModalOpen}
          check={check}
          setCheck={setCheck}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Myactivity;
