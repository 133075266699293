import React, { useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { AiOutlineMessage } from "react-icons/ai";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Moment from "react-moment";
import { getData, postData, putData } from "../../FetchService";

import ProfileImage from "../ProfileImage/ProfileImage";
import useClickOutside from "../../customHooks/useClickOutside";
import { setmini, minisearchbar } from "../Redux/Actions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MiniSideBar from "../Sidebar/MiniSideBar";
import { useDebounce } from "./useDebounceHook";
import './style.css'
function Navbar(props) {
  const { setOpenProfile } = props;
  const navigate = useNavigate();
  const userId = useSelector((state) => state);

  const Data = useSelector((state) => state.userData);

  const [open, setopen] = useState("");
  const [disable, setDisable] = useState('');
  const [type, settype] = useState("");
  const [suggest, setsuggest] = useState([]);
  const [result, setresult] = useState([]);
  const [followrequest, setfollowrequest] = useState([]);
  const [show, setshow] = useState(false);
  const [open1, setopen1] = useState(false);
  const [searchitem, setsearchitem] = useState("");
  const [active, setactive] = useState("");
  const [follow, setfollow] = useState("follow");
  const [notification, setnotification] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [count, setcount] = useState(0);

  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const userRef = useRef(null);
  const clickHandler = () => {
    setactive("");
    setopen1(false);
  };
  const userHandler = () => {
    setopen(false);
  };
  useClickOutside(notificationRef, clickHandler);
  useClickOutside(userRef, userHandler);
  if (show == true) {
    document.body.classList.add("smallSidebar");
  } else {
    document.body.classList.remove("smallSidebar");
  }

  const ToggleSideBar = () => {
    if (!!document.getElementsByTagName("body")[0].style.overflow) {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
      dispatch(setmini());
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "0px";
      dispatch(setmini());
    }
  };

  const search = async (value) => {
    setActiveOv(true);
    if (!!value?.type) {
      if (value.type == "user") {
        settype("Users");
      } else if (value.type == "event") {
        settype("Events");
      } else if (value.type == "club") {
        settype("Clubs");
      } else if (value.type == "healthArticle") {
        settype("Health Articles");
      }
      const res = await getData(
        `search?search=${value._id}&offset=${count}&limit=${5}&type=${
          value.type
        }`
      );
      setresult(res);
    } else {
      if (searchitem) {
        const response = await getData(
          `search?search=${searchitem}&offset=${count}&limit=${5}`
        );
        setresult(response);

        if (value !== "unset") {
          if (response?.data?.users) {
            settype("Users");
          } else if (response.data.events) {
            settype("Events");
          } else if (response.data.clubs) {
            settype("Clubs");
          } else if (response.data.healthArticles) {
            settype("Health Articles");
          }
        }
      }
    }
  };
  if (type == suggest.type) {
    setopen(false);
  }
  const notify = async () => {
    const res = await getData("notifications");
    setnotification(res.data);
  };
  const [activeOv, setActiveOv] = useState(true);
  const allfollowrequest = async () => {
    const res = await getData("all-follow-requests");
    setfollowrequest(res.data);
  };
  useEffect(() => {
    allfollowrequest();
    notify();
    // search()
  }, []);
  const accept = async (value) => {
    const data = {
      id: value,
    };
    const res = await putData("follow-accept", data);
    allfollowrequest();
  };
  const reject = async (value) => {
    const data = {
      id: value,
    };
    const res = await putData("follow-reject", data);
    allfollowrequest();
  };

  const handleOtherProfile = async (id) => {
    const res = await getData(`other-user-profile?id=${id}`);
    if (res.status == true) {
      navigate("/dashboard/otheruserprofile", { state: { details: id } });
    }
  };

  const handleClubsDetails = (id) => {
    navigate("/clubdetails", { state: { data: id } });
  };
  const handleEventDetails = (id) => {
    navigate("/events/eventdetailed", { state: { data: id } });
  };
  const handleHealthDetails = (id) => {
    navigate("/healtharticle/healthdetails", { state: { data: id } });
  };
  useEffect(() => {
    search("unset");
  }, [count]);
  const handleNext = () => {
    if (
      result?.data?.users.map((el) => {
        `${el.firstName} ${el.lastName}`.includes(searchitem);
      })
    ) {
      setcount(count + 1);
    } else if (
      result?.data?.events.map((el) => {
        el.template.includes(searchitem);
      })
    ) {
      setcount(count + 1);
    } else if (
      result?.data?.events.map((el) => {
        el.name.includes(searchitem);
      })
    ) {
      setcount(count + 1);
    } else if (
      result?.data?.healthArticles.map((el) => {
        el.name.includes(searchitem);
      })
    ) {
      setcount(count + 1);
    } else {
      alert("No More Data");
    }
  };
  const handlePrev = () => {
    if (count == 0) {
      alert("No more data");
    } else {
      setcount(count - 1);
    }
  };

  const dbValue = useDebounce(searchitem);

  const suggestion = async (vv) => {
    setActiveOv(false);
    if (vv) {
      const res = await getData(`suggestions?search=${vv}`);
      setsuggest(res.data);
    }
  };
  useEffect(() => {
    suggestion(dbValue);
  }, [dbValue]);

  console.log('suggest', suggest)

  return (
    <>
      <div className="col-12 mainHeader bg-white">
        <nav className="navbar navbar-expand-lg bg-white h-100">
          <div className="container-fluid">
            <button
              className="btn p-0 border-0 d-lg-none"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="offcanvasExample"
            >
              <img
                className="me-2"
                src="/assets/images/image-69.png"
                alt="sidebar close image"
                onClick={() => {
                  ToggleSideBar();
                }}
              />
            </button>
            <img
              className="me-2 d-none togglebtn sidebarmenuIcon"
              src="/assets/images/image-69.png"
              alt="sidebar close image"
              onClick={() => setshow(true)}
              id=""
            />
            <img
              className="me-2 d-none togglebtn sidebarRightArrow"
              src="/assets/images/image-114.png"
              alt="image-114"
              onClick={() => setshow(false)}
              id=""
            />
            <NavLink
              className="navbar-brand p-1 d-none d-sm-inline-block"
              to="/dashboard"
              style={{
                fontSize: "20px",
                color: "#121016",
                fontWeight: "500px",
              }}
            >
              {props.path}
            </NavLink>
            <div
              className="d-flex align-items-center ms-auto position-relative"
              id="navbarSupportedContent"
              style={{ marginBottom: "20px" }}
            >
              <div className="position-relative ms-auto mt-4 d-none d-lg-inline-block headerSearch">
                <button
                  className="btn p-0 border-0 "
                  type="button"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "15px",
                    zIndex: 3,
                    transform: "translateY(-50%)",
                  }}
                >
                  <img
                    src="/assets/images/image-71.png"
                    onClick={() => search(searchitem)}
                    style={{ width: "20px" }}
                    alt="image-71"
                  />
                </button>

                <button
                  style={{ zIndex: 3 }}
                  className="btn p-0 border-0 SearchRemove"
                  type="button"
                >
                  <img
                    onClick={() => setIsOpen(false)}
                    className="inputIcon"
                    src="/assets/images/image-72.png"
                    alt="image-72"
                  />
                </button>

                <input
                  className="form-control  shadow-none bg-light border-0 searchInput"
                  type="text"
                  style={{ padding: "12px 46px" }}
                  placeholder="Search"
                  aria-label="Search"
                  onKeyPress={(event) => {
                    if (event.charCode == 13) {
                      search(searchitem);
                    }
                  }}
                  // onKeyUp={() => suggestion(searchitem)}
                  onChange={(value) => {
                    setsearchitem(value.target.value);
                    settype("");
                    setIsOpen(true);
                  }}
                  onFocus={() => {
                    setActiveOv(false);
                    settype("");
                    setIsOpen(true);
                  }}
                />

                {suggest?.length ? (
                  <>
                    {/* <div
                      style={{
                        zIndex: 1,
                        height: "70vh",
                        overflow: "auto",
                        display: isOpen == true ? "block" : "none",
                      }}
                      className={`position-absolute w-100 bg-white  dropdownTabs ${
                        activeOv && "d-none"
                      }  }`}
                    >
                      {suggest?.map((item, i) => {
                        return (
                          <ul
                            className="nav nav-pills justify-content-between mb-3 "
                            id="pills-tab"
                            key={Math.random()}
                            role="tablist"
                            onClick={() => {
                              search(item);
                            }}
                          >
                            <li key={item._id} style={{ cursor: "pointer" ,zIndex:'3'}}>
                              {item._id}
                            </li>
                          </ul>
                        );
                      })}
                    </div> */}

                    <div
                      onClick={() => setActiveOv(true)}
                      className={`overlayForSearch ${activeOv && "d-none"}`}
                      style={{
                        position: "fixed",
                        zIndex: "0",
                        inset: "0",
                      }}
                    ></div>
                  </>
                ) : null}
                <div
                  className="bg-success"
                  style={{
                    zIndex: 3,
                    display: isOpen == true ? "block" : "none",
                  }}
                >
                  {!!type ? (
                    <div
                      className="position-absolute shadow w-100 bg-white navSearchBarUl"
                      style={{ top: "56px", zIndex: 4, }}
                    >
                      <ul
                        className="nav nav-pills navPillsSearch justify-content-between mb-3 "
                        id="pills-tab"
                        role="tablist"
                        style={{ borderBottom: "1px solid #E4E5E9" }}
                      >
                        <li
                          className={`nav-item ${type == "Users" ? "active" : ""}`}
                          role="presentation"
                          onClick={() => {
                            settype("Users");
                            setcount(0);
                          }}
                          // style={{
                          //   borderBottom:
                          //     type == "Users" ? "1.5px solid orange" : "",
                          // }}
                        >
                          <button
                            className="nav-link rounded-0 border-0 px-1 pt-0 text-dark bg-transparent$ "
                            id="users-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#users"
                            type="button"
                            role="tab"
                            aria-controls="users"
                            aria-selected="true"
                          >
                            Users
                          </button>
                        </li>
                        <li
                          className={`nav-item ${type == "Events" ? "active" : ""}`}
                          role="presentation"
                          onClick={() => {
                            settype("Events");
                            setcount(0);
                          }}
                        >
                          <button
                            className={`nav-link rounded-0 border-0 px-1 pt-0 text-dark bg-transparent  `}
                            id="events-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#events"
                            type="button"
                            role="tab"
                            aria-controls="events"
                            aria-selected="false"
                          >
                            Events
                          </button>
                        </li>
                        <li
                          className={`nav-item ${type == "Clubs" ? "active" : ""}`}
                          role="presentation"
                          onClick={() => {
                            settype("Clubs");
                            setcount(0);
                          }}
                        >
                          <button
                            className={`nav-link rounded-0 border-0 px-1 pt-0 text-dark bg-transparent `}
                            id="clubs-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#clubs"
                            type="button"
                            role="tab"
                            aria-controls="clubs"
                            aria-selected="false"
                          >
                            Clubs
                          </button>
                        </li>
                        <li
                          className={`nav-item ${type == "Health Articles" ? "active" : ""}`}
                          role="presentation"
                          onClick={() => {
                            settype("Health Articles");
                            setcount(0);
                          }}
                        >
                          <button
                            className="nav-link rounded-0 border-0 px-1 pt-0  text-dark bg-transparent"
                            id="health-articles-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#health-articles"
                            type="button"
                            role="tab"
                            aria-controls="health-articles"
                            aria-selected="false"
                          >
                            Health Articles
                          </button>
                        </li>
                      </ul>

                      <div
                        style={{ overflow: "auto" }}
                        className="bg-white tab-content overflow-hidden"
                        id="pills-tabContent"
                      >
                        {type == "Users"
                          ? result?.data?.users?.map((item, i) => {
                              return (
                                <div

                                  key={Math.random()}
                                  id="users"
                                  role="tabpanel"
                                  aria-labelledby="users-tab"
                                  tabIndex="0"
                                >
                                  {/* <NavLink to='otheruserprofile' > */}
                                  <div className="row g-2 align-items-center userDiv">
                                    <div className="col-auto" >
                                      <div className="userimgSearch">
                                      {item.image ? (
                                        <img
                                          className="followerImg"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            objectFit:"cover",
                                          }}
                                          src={item.image}
                                        />
                                      ) : (
                                        <AccountCircleIcon
                                          iconStyle={{ fontSize: "large" }}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            color: "#dbdbdb",
                                            objectFit:'cover',
                                          }}
                                        />
                                      )}
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleOtherProfile(item._id)
                                        }
                                        className="fw-bold userEllipsis"
                                      >
                                        {item.firstName} {item.lastName}
                                      </div>
                                    </div>
                                  </div>
                                  {/* </NavLink> */}
                                </div>
                              );
                            })
                          : ""}
                        {type == "Users" ? (
                          <div className="d-flex justify-content-center me-3">
                            <button
                              className="btn me-3 prevBtnSearch"
                              style={{
                                display:
                                  result?.data?.users?.length < 5
                                    ? "none"
                                    : ""
                                    ? "none"
                                    : "",
                                backgroundColor: "#241840",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={handlePrev}
                              disabled={disable == 'user'?false:true}
                            >
                              Prev
                            </button>
                            <button
                              className="btn careerBtn "
                              style={{
                                display:
                                  result?.data?.users?.length < 5 ? "none" : "",
                                backgroundColor: "#EF5036",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                handleNext();
                                setDisable('user');
                              }}
                            >
                              Next
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {type == "Events"
                          ?
                          result?.data?.events?.length > 0 ? 
                          result?.data?.events?.map((item, i) => {
                            return (
                              <div
                                key={Math.random()}
                                style={{ left: "20px",Zindex:'3' }}
                                id="events"
                                role="tabpanel"
                                aria-labelledby="events-tab"
                                tabIndex="0"
                              >
                                <div className="row g-2 align-items-center userDiv">
                                  <div className="col-auto"  >
                                    <div className="userimgSearch">

                                    <img
                                      className="followerImg"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "50%",
                                        objectFit:"cover",
                                      }}
                                      src={
                                        item.image
                                          ? item.image
                                          : "/assets/images/image-262.png"
                                      }
                                      alt="image-262"
                                    />
                                    </div>
                                  </div>
                                  <div
                                    className="col"
                                    style={{ cursor: "pointer" , }}
                                    onClick={() => handleEventDetails(item)}
                                  >
                                    <div className="fw-bold userEllipsis" style={{wordWrap: 'break-word'}}>
                                      {item.template}
                                    </div>
                                    <div className="updatetime">
                                      <Moment format="MMMM Do">
                                        {item.startDate} to {item.endDate} :
                                        {item.startDate}-{item.endDate} until
                                        start
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }): 
                          <div className="text-dark">No Data</div>
                          : ""}
                        {type == "Events" ? (
                          <div className="d-flex justify-content-center me-3">
                            <button
                              className="btn me-3 prevBtnSearch"
                              style={{
                                display:
                                  result?.data?.events?.length < 5
                                    ? "none"
                                    : ""
                                    ? "none"
                                    : "",
                                backgroundColor: "#241840",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={handlePrev}
                              disabled={disable =='events'?false:true}
                            >
                              Prev
                            </button>
                            <button
                              className="btn  careerBtn"
                              style={{
                                display:
                                  result?.data?.events?.length < 5
                                    ? "none"
                                    : "",
                                backgroundColor: "#EF5036",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                handleNext();
                                setDisable('events');
                              }}
                            >
                              Next
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {type == "Clubs"
                          ? result?.data?.clubs?.map((item, i) => {
                              return (
                                <div
                                  key={Math.random()}
                                  style={{ left: "40px" }}
                                  id="clubs"
                                  role="tabpanel"
                                  aria-labelledby="clubs-tab"
                                  tabIndex="0"
                                >
                                  <div className="row g-2 align-items-center userDiv">
                                    <div className="col-auto">
                                      <div className="userimgSearch">
                                      <img
                                        className="followerImg"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          borderRadius: "50%",
                                          objectFit:"cover",
                                        }}
                                        src={
                                          item.image
                                            ? item.image
                                            : "/assets/images/image-154.png"
                                        }
                                        alt="image-154"
                                      />

                                      </div>
                                    </div>
                                    <div
                                      className="col"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleClubsDetails(item)}
                                    >
                                      <div className="fw-bold userEllipsis" style={{wordWrap: 'break-word'}}>{item.name}</div>
                                      <div className="updatetime">
                                        Created on :{" "}
                                        <Moment format="MMM Do YY">
                                          {item.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      {item.isPrivate == true ? (
                                        <button
                                          type="button"
                                          className="btn border-0 shadow-none followBtn followbtnsearch"
                                          style={{
                                            backgroundColor: "#fde5e1",
                                            color: "#EF5036",
                                            borderRadius: "23px",
                                            fontSize: "12px",
                                            lineHeight: "13.2px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Private
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn border-0 shadow-none followBtn followbtnsearch"
                                          style={{
                                            backgroundColor: "#fde5e1",
                                            color: "#EF5036",
                                            borderRadius: "23px",
                                            fontSize: "12px",
                                            lineHeight: "13.2px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          Public
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                        {type == "Clubs" ? (
                          <div className="d-flex justify-content-center me-3">
                            <button
                              className="btn me-3 prevBtnSearch"
                              style={{
                                display:
                                  result?.data?.clubs?.length < 5
                                    ? "none"
                                    : ""
                                    ? "none"
                                    : "",
                                backgroundColor: "#241840",
                                // color: "white",
                                // width: "23%",
                                // borderRadius: "10px",
                              }}
                              onClick={handlePrev}
                              disabled={disable == 'club'?false:true}
                            >
                              Prev
                            </button>
                            <button
                              className="btn careerBtn "
                              style={{
                                display:
                                  result?.data?.clubs?.length < 5 ? "none" : "",
                                backgroundColor: "#EF5036",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                handleNext();
                                setDisable('club');
                              }}
                            >
                              Next
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {type == "Health Articles"
                          ? result?.data?.healthArticles?.map((item, i) => {
                              return (
                                <div
                                  key={Math.random()}

                                  id="health-articles"
                                  role="tabpanel"
                                  aria-labelledby="health-articles-tab"
                                  tabIndex="0"
                                >
                                  <div className="row g-2 align-items-center userDiv">
                                    <div className="col-auto">
                                      <div className="userimgSearch">
                                      <img
                                        className="followerImg"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          borderRadius: "50%",
                                          objectFit:"cover",
                                        }}
                                        src={
                                          item.image
                                            ? item.image
                                            : "/assets/images/image-180.png"
                                        }
                                      />

                                      </div>
                                    </div>
                                    <div
                                      className="col"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleHealthDetails(item)}
                                    >
                                      <div className="fw-bold userEllipsis" style={{wordWrap: 'break-word'}}>{item.name}</div>
                                      <div className="updatetime">
                                        <Moment format="MMMM Do YYYY, h:mm">
                                          {item.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <button
                                        type="button"
                                        className="btn border-0 shadow-none followBtn followbtnsearch"
                                        style={{
                                          backgroundImage:
                                            "url(/assets/images/Rectangle.png)",
                                          backgroundRepeat: "no-repeat",
                                          fontSize: "12px",
                                          lineHeight: "13.2px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#EF5036",
                                        }}
                                      >
                                        {item.categories[0].name}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                        {type == "Health Articles" ? (
                          <div className="d-flex justify-content-center me-3">
                            <button
                              className="btn btn-secondary me-3 prevBtnSearch"
                              style={{
                                display:
                                  result?.data?.healthArticles?.length < 5
                                    ? "none"
                                    : ""
                                    ? "none"
                                    : "",
                                backgroundColor: "#241840",
                                color: "white",
                                width: "23%",
                                borderRadius: "10px",
                              }}
                              onClick={handlePrev}
                              disabled={disable == 'healthArticles'?false:true}
                            >
                              Prev
                            </button>
                            <button
                              className="btn careerBtn"
                              style={{
                                display:
                                  result?.data?.healthArticles?.length < 5 ? "none" : "",
                                // backgroundColor: "#EF5036",
                                // color: "white",
                                // width: "23%",
                                // borderRadius: "10px",
                              }}
                              onClick={() => {
                                handleNext();
                                setDisable('healthArticles');
                              }}
                            >
                              Next
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ul
                className="navbar-nav flex-row align-items-center gap-3 gap-lg-0 ps-lg-4"
                style={{ marginBottom: "-15px" }}
              >
                <li className="nav-item d-lg-none">
                  <button
                    className="btn p-1 border-0"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTop"
                    aria-controls="offcanvasTop"
                    onClick={() => dispatch(minisearchbar())}
                  >
                    <img
                      className="inputIcon"
                      src="/assets/images/image-71.png"
                      alt="search icon image"
                    />
                  </button>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link py-0"
                    aria-current="page"
                    to="/chat"
                  >
                    <AiOutlineMessage
                      style={{ height: "22px", width: "22px", color: "#222" }}
                    />
                  </NavLink>
                </li>
                <li className="nav-item dropdown notificationDrop">
                  <NavLink
                    className={`nav-link py-0 ${
                      active == "notification" ? "show" : ""
                    }`}
                    data-bs-toggle="dropdown"
                    aria-expanded={`${
                      active == "notification" ? "true" : "false"
                    }`}
                    onClick={() =>
                      setactive((prev) => {
                        if (prev === "notification") {
                          return "";
                        } else {
                          return "notification";
                        }
                      })
                    }
                    data-bs-auto-close="outside"
                  >
                    <NotificationsNoneOutlinedIcon
                      style={{
                        position: "relative",
                        height: "28px",
                        width: "28px",
                        // marginBottom: "-6px",
                      }}
                    />

                    <Avatar
                      style={{
                        height: "14px",
                        width: "14px",
                        textAlign: "center",
                        position: "absolute",
                        marginTop: "-28px",
                        backgroundColor: "#f15a22",
                        marginLeft: "13px",
                      }}
                    >
                      <span
                        style={{
                          height: "12px",
                          width: "13px",
                          margin: "auto",
                          fontSize: "13px",
                        }}
                      >
                        {notification.length}
                      </span>
                    </Avatar>
                  </NavLink>
                  <div
                    ref={notificationRef}
                    className={`dropdown-menu dropdown-menu-end position-absolute border-0 notifiDrop ${
                      active == "notification" ? "show" : ""
                    } `}
                    data-bs-popper={`${active == "notification" ? "show" : ""}`}
                  >
                    <div className="dropdown-header p-3">
                      <h5 className="dropdown-title fw-bold m-0 text-dark">
                        Notification
                      </h5>
                    </div>
                    <div className={`dropdown-body py-2 px-3 `}>
                      <ul
                        className="nav nav-pills bg-light mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item w-50" role="presentation">
                          <button
                            className={`nav-link px-1 w-100 ${
                              follow == "follow" && "active"
                            } `}
                            id="followRequests-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#followRequests"
                            type="button"
                            role="tab"
                            aria-controls="followRequests"
                            aria-selected={`${
                              follow == "follow" ? "true" : "false"
                            }`}
                            onClick={() => setfollow("follow")}
                          >
                            Follow Requests
                          </button>
                        </li>
                        <li className="nav-item w-50" role="presentation">
                          <button
                            className={`nav-link px-1 w-100 ${
                              follow == "note" && "active"
                            } `}
                            id="notifications-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#notifications"
                            type="button"
                            role="tab"
                            aria-controls="notifications"
                            aria-selected={`${
                              follow == "note" ? "true" : "false"
                            }`}
                            onClick={() => setfollow("note")}
                          >
                            Notifications
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className={`tab-pane fade   ${
                            follow == "follow" && "active show"
                          }  `}
                          id="followRequests"
                          role="tabpanel"
                          aria-labelledby="followRequests-tab"
                          tabIndex="0"
                        >
                          {followrequest?.map((item) => {
                            return (
                              <div
                                className="row g-2 align-items-center userDiv"
                                key={item._id}
                              >
                                <div className="col-auto">
                                  <div className="userimgSearch">

                                  {item.image ? (
                                    <img
                                      className="followerImg"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "50%",
                                        objectFit:"cover",
                                      }}
                                      src={item.image}
                                    />
                                  ) : (
                                    <AccountCircleIcon
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        color: "#dbdbdb",
                                        objectFit:"cover",
                                      }}
                                    />
                                  )}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="notificationText">
                                    <span className="fw-bold userEllipsis">
                                      {item.firstName} {item.lastName}{" "}
                                    </span>{" "}
                                    started following you
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-theme2 btn-sm shadow-none followBtn followbtnsearch"
                                    style={{ marginRight: 20 }}
                                    onClick={() => accept(item._id)}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    type="button"
                                    style={{ backgroundColor: "#f15a22" }}
                                    className="btn  btn-sm shadow-none followBtn followbtnsearch text-white"
                                    onClick={() => reject(item._id)}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className={`tab-pane fade ${
                            follow == "note" && "active show"
                          }`}
                          id="notifications"
                          role="tabpanel"
                          aria-labelledby="notifications-tab"
                          tabIndex="0"
                        >
                          {notification.map((item) => {
                            return (
                              <div
                                className="row g-2 align-items-center mb-3"
                                key={item._id}
                              >
                                <div className="col-auto">
                                  <div className="userimgSearch">
                                  <img
                                    className="followerImg"
                                    src={
                                      item.type == 1
                                        ? item.details.image
                                          ? item.details.image
                                          : ""
                                        : item.type == 2
                                        ? item.details.admin.image
                                          ? item.details.admin.image
                                          : ""
                                        : "https://media.istockphoto.com/id/1349258040/photo/teenager-doing-homework-side-view.jpg?s=612x612&w=is&k=20&c=dOffHvzKWpHOwxfnRNQWN4I2j7SW1o6phTa_5mkTY3A="
                                    }
                                  />

                                  </div>
                                </div>
                                <div className="col">
                                  <div className="notificationText">
                                    {item.type == 1
                                      ? `${item.details.firstName}  ${item.details.lastName} sent you request`
                                      : item.type == 2
                                      ? `${item.details.admin.firstName}  ${item.details.admin.lastName} invite you an event`
                                      : ""}
                                  </div>
                                  <div className="text-dark updatetime">
                                    Just now
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-footer pb-2 px-3">
                      <div className="d-flex justify-content-center pt-3 borderFooter">
                        <NavLink
                          to="/notification"
                          className="text-decoration-none d-flex align-items-center allnotification"
                        >
                          See all notifications{" "}
                          <img
                            className="ms-2 rightArrow"
                            src="/assets/images/image-239.png"
                            alt="image-239"
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown" ref={userRef}>
                  <div
                    onClick={() => {
                      // if (!!open1) {
                      //   document.body.classList.remove("leftPartShow");
                      // } else {
                      //   document.body.classList.add("leftPartShow");
                      // }

                      setopen((prev) => !prev);
                      setOpenProfile((prev) => !prev);
                    }}
                    className={`nav-link py-0 d-flex align-items-center dashBoardToggle ${
                      open == true ? "show" : ""
                    }`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={open == true ? "true" : "false"}
                  >
                  <ProfileImage image={Data?.image} height="26px" width="26px" borderRadius="50%" />


                    <img
                      className="dropdownArrow d-none d-md-inline-block"
                      src="/assets/images/image-43.png"
                      alt="image"
                    />
                  </div>
                  <ul
                    className={`dropdown-menu dropdown-menu-end position-absolute border-0 userDrop ${
                      open == true ? "show" : ""
                    }`}
                    data-bs-popper={open == true ? "static" : ""}
                  >
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/homepage"
                        onClick={() => {
                          localStorage.clear();
                          userId.user("");
                        }}
                      >
                        <img
                          className="me-2 userDropitem"
                          src="/assets/images/logout.png"
                          alt=""
                        />{" "}
                        Logout
                      </NavLink>
                    </li>
                    <li className="dropdown-item">
                      {/* <NavLink className="dropdown-item" to="/help"> */}
                      <img
                        className="me-2 userDropitem"
                        src="/assets/images/help.png"
                        alt=""
                      />{" "}
                      Help
                      {/* </NavLink> */}
                    </li>
                    <li className="dropdown-item">
                      {/* <NavLink className="dropdown-item" to="/setting"> */}
                      <img
                        className="me-2 userDropitem"
                        src="/assets/images/setting.png"
                        alt=""
                      />{" "}
                      Setting
                      {/* </NavLink> */}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
