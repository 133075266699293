import logo from "./logo.svg";
import "./App.css";
import Pincode from "react-pincode";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import React, { Suspense, lazy } from "react";
import Header from "./Common/Header/Header";
import Footer from "./Common/Footer/Footer";
import useNavigate from "react-router-dom";
import { useLocation } from "react-router-dom";
import FooterHome from "./Common/Footer/FooterHome";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Common/Sidebar";
import PrivateRoutes from "./Common/PrivateRoutes";
import { getData } from "./FetchService";
import { Provider, useDispatch } from "react-redux";
import { addUnit, setUsers } from "./Common/Redux/Actions";
import Clubinvited from "./Pages/Clubs/ClubInvited";
import LeftSide from "./Common/Section/LeftSide";
import Loading from "./Common/Loading";


const BookMarked = lazy(() => import("./Pages/HealthArticle/Bookmarked"));
const Events = lazy(() => import("./Pages/Events"));
const Club = lazy(() => import("./Pages/Clubs"));
const ClubInvited = lazy(() => import("./Pages/Clubs/ClubInvited"));
const ChatUser = lazy(() => import("./Pages/Chat"));
const HealthArticle = lazy(() => import("./Pages/HealthArticle"));
const Bmidata = lazy(() => import("./Common/Bmidata"));
const About = lazy(() => import("./Pages/About"));
const Final1 = lazy(() => import("./Pages/Final1"));
const Final2 = lazy(() => import("./Pages/Final2"));
const Login = lazy(() => import("./Pages/Login"));
const Register = lazy(() => import("./Pages/Register"));
const Verify = lazy(() => import("./Pages/Verify"));
const Forgot = lazy(() => import("./Pages/Forgot"));
const Reset = lazy(() => import("./Pages/Reset"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const Features = lazy(() => import("./Pages/Features"));
const Contact = lazy(() => import("./Pages/Contact"));
const Career = lazy(() => import("./Pages/Career"));
const Notification = lazy(() => import("./Pages/Notification"));
const Careerdetails = lazy(() => import("./Pages/Careerdetails"));
const Subscription = lazy(() => import("./Pages/Subscription"));
const Termsandcondition = lazy(() => import("./Pages/Termsandcondition"));
const Tereto = lazy(() => import("./Pages/Dashboard/Tereto"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const TodaysTarget = lazy(() => import("./Pages/Dashboard/TodaysTarget"));

const Myreto = lazy(() => import("./Pages/Dashboard/Myreto"));
const BadgesEarnedMap = lazy(() => import("./Pages/Dashboard/BadgesEarnedMap"));
const BadgesEarned = lazy(() => import("./Pages/Dashboard/BadgesEarned"));
const EarnedMedals = lazy(() => import("./Pages/Dashboard/EarnedMedals"));
const CancelOrder = lazy(() => import("./Pages/Dashboard/CancelOrder"));
const DeliveredOrder = lazy(() => import("./Pages/Dashboard/DeliveredOrder"));
const Merchandise = lazy(() => import("./Pages/Merchandise"));
const YourActivity = lazy(() => import("./Pages/yourActivity"));

const Profile = lazy(() => import("./Pages/Dashboard/Profile"));
const OtherUserProfile = lazy(() => import("./Pages/OtherUserProfile"));
const HealthDetails = lazy(() => import("./Pages/HealthArticle/HealthDetails"));
const EventsDetailInvited1 = lazy(() =>
  import("./Pages/Events/Eventsdetailedinvited1")
);
const EventDetailFree = lazy(() => import("./Pages/Events/Eventsdetiledfree"));
const Createevents = lazy(() => import("./Pages/Events/Createevents"));
const EventAccepted = lazy(() => import("./Pages/Events/EventsAccepted"));
const Myownevents = lazy(() => import("./Pages/Events/Myownevents"));
const Createeventinvited1 = lazy(() =>
  import("./Pages/Events/Createeventinvited1")
);

const EventProgress = lazy(() => import("./Pages/Events/EventProgress"));
const EventCompleted = lazy(() => import("./Pages/Events/EventCompleted"));
const EventGroupCompleted = lazy(() =>
  import("./Pages/Events/EventGroupCompleted")
);
const CreateClub = lazy(() => import("./Pages/Clubs/CreateClub"));
const ClubDetails = lazy(() => import("./Pages/Clubs/ClubDetails "));
const CommonActivity = lazy(() => import("./Common/CommonActivity"));
const FliterActivity = lazy(() =>
  import("./Common/CommonActivity/FilterActivity")
);
const CorporateEvent = lazy(() => import("./Pages/Events/CorporateEvent"));

function App() {
  const [userdata, setuserdata] = useState([]);

  const dispatch = useDispatch();
  const Token = localStorage.getItem("token");
  const userprofile = async () => {
    const profile = await getData("profile");
    dispatch(setUsers(profile.data));
    localStorage.setItem("data", JSON.stringify(profile.data));
  };
  useEffect(() => {
    if (Token) {
      userprofile();
    }
  }, []);

  const getUnit = async () => {
    let res = await getData("get-preferences");
    dispatch(
      addUnit({
        height: res.data.height,
        weight: res.data.weight,
        distance: res.data.distance,
      })
    );
  };

  useEffect(() => {
    if (Token) {
      getUnit();
    }
  }, []);

  return (
    <div>
    
      <BrowserRouter>
        <Inner />
      </BrowserRouter>
      
      
    </div>
  );
}

function Inner() {
  const location = useLocation();
  return (

    <Suspense fallback={<Loading />}>
      {location.pathname == "/dashboard" ||
      location.pathname == "/leftside" ||
      location.pathname == "/dashboard/otheruserprofile" ||
      location.pathname == "/dashboard/activity" ||
      location.pathname == "/events" ||
      location.pathname == "/clubs" ||
      location.pathname == "/club-invited" ||
      location.pathname == "/chat" ||
      location.pathname == "/healtharticle" ||
      location.pathname == "/healtharticle/healthdetails" ||
      location.pathname == "/bmidata" ||
      location.pathname == "/events/eventdetailed" ||
      location.pathname == "/create-event" ||
      location.pathname == "/events/eventdetails" ||
      location.pathname == "/events/acceptedevents" ||
      location.pathname == "/events/ownevents" ||
      location.pathname == "/createevents" ||
      location.pathname == "/events/eventprogress" ||
      location.pathname == "/events/eventcompleted" ||
      location.pathname == "/events/groupeventcompleted" ||
      location.pathname == "/dashboard/profile" ||
      location.pathname == "/bookmark" ||
      location.pathname == "/notification" ||
      location.pathname == "/dashboard/tereto" ||
      location.pathname == "/dashboard/todaystarget" ||
      location.pathname == "/dashboard/myreto" ||
      location.pathname == "/dashboard/badges-earned-map" ||
      location.pathname == "/dashboard/badges-earned" ||
      location.pathname == "/merchandise" ||
      location.pathname == "/dashboard/earnedmedals" ||
      location.pathname == "/dashboard/trackorder" ||
      location.pathname == "/dashboard/deliveredorder" ||
      location.pathname == "/clubdetails" ||
      location.pathname == "/common-activity" ||
      location.pathname == "/following-activity" ||
      location.pathname == "/event-corporate" ||
      location.pathname == "/clubs/createclub" ? (
        " "
      ) : (
        <Header />
      )}
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            exact
            path="/"
            element={
              localStorage.getItem("token") ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          <Route exact path="/leftside" element={<LeftSide />} />

          <Route exact index path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dashboard/activity" element={<YourActivity />} />
          {/* <Route exact path="/activity" element={<YourActivity />} /> */}
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/event-corporate" element={<CorporateEvent />} />
          <Route exact path="/create-event" element={<Createevents />} />
          <Route exact path="/clubs" element={<Club />} />
          <Route exact path="/chat" element={<ChatUser />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/club-invited" element={<Clubinvited />} />
          <Route exact path="/merchandise" element={<Merchandise />} />
          <Route exact path="/clubs/createclub" element={<CreateClub />} />
          <Route exact path="/healtharticle" element={<HealthArticle />} />
          <Route exact path="/dashboard/profile" element={<Profile />} />
          <Route
            exact
            path="/dashboard/otheruserprofile"
            element={<OtherUserProfile />}
          />

          <Route
            exact
            path="/healtharticle/healthdetails"
            element={<HealthDetails />}
          />
          <Route exact path="/bmidata" element={<Bmidata />} />
          <Route exact path="/dashboard/tereto" element={<Tereto />} />

          <Route exact path="/dashboard/myreto" element={<Myreto />} />
          <Route exact path="/clubdetails" element={<ClubDetails />} />
          <Route exact path="/common-activity" element={<CommonActivity />} />
          <Route
            exact
            path="/following-activity"
            element={<FliterActivity />}
          />

          <Route
            exact
            path="/dashboard/todaystarget"
            element={<TodaysTarget />}
          />
          <Route
            exact
            path="/dashboard/badges-earned-map"
            element={<BadgesEarnedMap />}
          />

          <Route
            exact
            path="/dashboard/badges-earned"
            element={<BadgesEarned />}
          />
          <Route
            exact
            path="/dashboard/earnedmedals"
            element={<EarnedMedals />}
          />
          <Route exact path="/dashboard/trackorder" element={<CancelOrder />} />
          <Route
            exact
            path="/dashboard/deliveredorder"
            element={<DeliveredOrder />}
          />
          <Route
            exact
            path="/events/eventdetailed"
            element={<EventsDetailInvited1 />}
          />
          <Route
            exact
            path="/events/eventdetails"
            element={<EventDetailFree />}
          />
          <Route
            exact
            path="/events/acceptedevents"
            element={<EventAccepted />}
          />
          <Route exact path="/events/ownevents" element={<Myownevents />} />
          <Route exact path="/createevents" element={<Createeventinvited1 />} />
          <Route
            exact
            path="/events/eventprogress"
            element={<EventProgress />}
          />
          <Route
            exact
            path="/events/eventcompleted"
            element={<EventCompleted />}
          />
          <Route
            exact
            path="/events/groupeventcompleted"
            element={<EventGroupCompleted />}
          />

          <Route exact path="/bookmark" element={<BookMarked />} />
        </Route>
        <Route exact path="/" element={<Final2 />} />
        <Route exact path="/homepage" element={<Final2 />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/privacy" element={<Privacy />} />

        <Route exact path="/feature" element={<Features />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/careerdetails" element={<Careerdetails />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/terms" element={<Termsandcondition />} />
      </Routes>
      {location.pathname == "/dashboard" ||
      location.pathname == "/dashboard/otheruserprofile" ||
      location.pathname == "/dashboard/activity" ||
      location.pathname == "/common-activity" ||
      location.pathname == "/following-activity" ||
      location.pathname == "/events" ||
      location.pathname == "/clubs" ||
      location.pathname == "/chat" ||
      location.pathname == "/leftside" ||
      location.pathname == "/clubs/createclub" ||
      location.pathname == "/clubdetails" ||
      location.pathname == "/club-invited" ||
      location.pathname == "/healtharticle" ||
      location.pathname == "/healtharticle/healthdetails" ||
      location.pathname == "/bmidata" ||
      location.pathname == "/events/eventdetailed" ||
      location.pathname == "/events/eventdetails" ||
      location.pathname == "/events/acceptedevents" ||
      location.pathname == "/event-corporate" ||
      location.pathname == "/events/ownevents" ||
      location.pathname == "/createevents" ||
      location.pathname == "/create-event" ||
      location.pathname == "/events/eventprogress" ||
      location.pathname == "/events/eventcompleted" ||
      location.pathname == "/events/groupeventcompleted" ||
      location.pathname == "/dashboard/profile" ||
      location.pathname == "/dashboard/tereto" ||
      location.pathname == "/dashboard/todaystarget" ||
      location.pathname == "/dashboard/myreto" ||
      location.pathname == "/dashboard/badges-earned-map" ||
      location.pathname == "/dashboard/earnedmedals" ||
      location.pathname == "/dashboard/badges-earned" ||
      location.pathname == "/dashboard/trackorder" ||
      location.pathname == "/merchandise" ||
      location.pathname == "/notification" ||
      location.pathname == "/dashboard/deliveredorder" ||
      location.pathname == "/bookmark" ? (
        ""
      ) : location.pathname == "/career" ? (
        ""
      ) : location.pathname == "/" || location.pathname == "/homepage" ? (
        <FooterHome />
      ) : (
        <Footer />
      )}
    </Suspense>
  );
}
export default App;
