import React, { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useClickOutside from "../../customHooks/useClickOutside";

function Header({ background, login }) {
  const myref = useRef(null)
  const location = useLocation();
  const [minilog , setminilog] =  useState()
  const clickHandler = () =>{
    setminilog('')
  }
  useClickOutside(myref ,clickHandler)
  const protectedR = ["/following", "/activity", "/dashboard"];
  if (protectedR.includes(location.pathname)) {
    return null;
  }else{
    
  }

  const removeAuthBtns = location.pathname === '/privacy' || location.pathname === "/terms" || location.pathname === "/about"
  
  return (
    <>
      <header
        className={`navbar_header fixed-top header-top `}
          style={{background: location.pathname == "/about" ||
          location.pathname == "/" ||
          location.pathname == "/homepage"|| location.pathname == "/subscription"
          ? "#fff"
          : "#c2dce3"
          }}
      >
        <nav className="navbar navbar-expand-lg py-0 bg_container1 py-2">
          <div className="container bg_container2">
            <NavLink className="navbar-brand p-1" to="/homepage">
              <img
                src="./assets/images/image.png"
                alt="logo"
                className="d-inline-block align-text-top logo_image"
              />
            </NavLink>
            {location.pathname == "/homepage" ?
              <NavLink
                className="d-lg-none"
                // to="/login"
                onClick={()=>setminilog('mini')}
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img
                  className="sode_bar"
                  src="./assets/images/image-69.png"
                  alt="Side_Bars"
                />
              </NavLink> : (
               !removeAuthBtns ?
               <NavLink
               className="d-lg-none"
               // to="/login"
               onClick={()=>setminilog('mini')}
               data-bs-toggle="offcanvas"
               data-bs-target="#offcanvasRight"
               aria-controls="offcanvasRight"
            
             >
               <img
                 className="sode_bar"
                 src="./assets/images/image-69.png"
                 alt="Side_Bars"
               />
             </NavLink>
             :
             <></>
                
              )
            }


            <div
              className="collapse navbar-collapse justify-content-end
                        header_collapse"
              id="navbarNav"
            >
              <div className="d-flex gap-3 button_group">
                <div className="d-flex align-items-center gap-3">
                  {location.pathname == "/login" ? (
                    <NavLink
                      to="/register"
                      className="btn text-decorection-none text-white reg_button border-muted bg-theme1 active"
                    >
                      Register
                    </NavLink>
                  ) : (
                    ""
                  )}
                  { location.pathname == "/" ||
                    location.pathname == "/homepage" ?
                    <>
                      <NavLink
                    to="/register"
                    className="btn text-decorection-none text-white reg_button border-muted bg-theme1 active"
                  >
                    Register
                  </NavLink> 
                  <NavLink
                    to="/login"
                    className={`btn text-decorection-none text-black  `}
                    style={{border:"1px solid #646464", textDecoration:'none',
                    fontSize: '14px',
                    fontStyle: 'italic',
                    fontWeight:'bold',
                    width: '100px' ,
                    height: '40px ',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px'}}
                  >
                    Log In
                  </NavLink>
                    </>:''
                  }
                  {location.pathname == "/about" ||
             
                    location.pathname == "/privacy" ||
                    location.pathname == "/feature" ||
                    location.pathname == "/contact" ||
                    location.pathname == "/career" ||
                    location.pathname == "/register" ||
                    location.pathname == "/terms" ? (
                    <>
                      {location.pathname == "/homepage" ? <NavLink
                        to="/register"
                        className="btn text-decorection-none text-white reg_button border-muted bg-theme1 active"
                      >
                        Register
                      </NavLink> : ""}
                      <NavLink
                        to="/login"
                        className={`btn text-decorection-none text-white reg_button border-muted bg-theme1 active `}
                       
                      >
                        Log In
                      </NavLink>
                    </>

                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        className="offcanvas offcanvas-end zi-999 offcan"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body body_offcanvas">
          <form className="row align-items-center gap-4 mx-3">
            <NavLink
              to="/register"
              className="col-12 text-decorection-none text-white reg_button active"
            >
              Register
            </NavLink>
            <NavLink
              to="/login"
              className="col-12 text-decorection-none text-black login_button border-muted"
            >
              Log In
            </NavLink>
          </form>
        </div>
      </div>
      <div ref={myref} className={`offcanvas offcanvas-end zi-999 ${minilog == 'mini' ? 'show' : ''}`}  tabIndex={minilog == 'mimi' ? '-1' :''}
        role={minilog == 'mimi' ? 'dialog' :''}
        aria-modal= {minilog == 'mimi' ? 'true' :'false'}
        id="offcanvasRight" aria-labelledby="offcanvasRightLabel" 
        >
        <div className="offcanvas-header">
            <button onClick={()=>setminilog('')} type="button" className="btn-close text-reset shadow-none"
                data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <form className=" gap-3 d-flex flex-column alignItems-flexEnd justifyContent-flexEnd text-decoration-none">
                <NavLink onClick={()=>setminilog('')} style={{textDecoration : 'none'}} to="/register"className=" text-black reg_button active">Register</NavLink>
                <NavLink onClick={()=>setminilog('')} style={{textDecoration : 'none'}} to="/login"className="text-decorection-none text-black login_button border-muted">Log In</NavLink>
            </form>
        </div>
    </div>
    </>
  );
}

export default Header;
