import React from "react";
import Avatar from "@mui/material/Avatar";
function ProfileImage(props) {
  const { image, height, width, borderRadius  } = props;
  return (
    <>
    {image && image != "null" ? (
        <img
          // className="w-100 h-100"
          src={image}
          style={{borderRadius, height,width}}
        />
      ) : (
        <Avatar
          style={{borderRadius, height,width}}
        ></Avatar>
      )}
    </>

  );
}

export default ProfileImage;
