import { React, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { getData } from "../../../FetchService";
function ChartModal(props) {
  const { modalopen, activeActivityId, setModalOpen, meter, check, setcheck } =
    props;

  const [state, setState] = useState([]);

  const [minute, setMinute] = useState("");

  const length = state[0]?.length;
  const [oneactivity, setoneactivity] = useState([]);
  const [distance, setDistance] = useState("");
  const getoneactivity = async (activeActivityId ) => {
    const path = `activity?id=${activeActivityId ?activeActivityId._id : ""}`;

    const res = await getData(path);
    setoneactivity(res.data);

    let a = [];
    let initialValue = 0;
    res?.data.map((item) => {
      a.push(item?.metersPerMinute);
    });
    const sum = a[0]?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      initialValue
    );

    setDistance(sum);
    setMinute(a);
  };
  const onClick = () => {
    setState(minute);
  };
 
  const categories = [];
 
  useEffect(() => {
    getoneactivity(activeActivityId )
  }, [modalopen]);
  useEffect(() => {
    onClick();
  }, [minute]);

  const options = {
    title:{
      text:''
  },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolWidth: 5,
      symbolHeight: 8,
    },
    xAxis: {
      tickColor: "#FFFFFF",
      categories: Array.from({ length: length }, (_, index) => index + 1),
     
    },
    yAxis: {
      tickColor: "#FFFFFF",
      gridLineColor: "#FFFFFF",
     
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
        },
      },
    },
    tooltip: {
      shared: true,
      
    },
    series: [
      {
        type: "spline",

        color: "#ff671b",
        data: state[0],
      },
    ],
  };
 

  return (
    <div>
      <div
        className={`modal fade modal-2 viewanalysismodal ${
          modalopen == "modal" ? "show" : ""
        }`}
        id="viewanalics"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden={modalopen == "modal" ? "" : "true"}
        aria-modal={modalopen == "modal" ? "true" : ""}
        role={modalopen == "modal" ? "dialog" : ""}
        style={{ display: modalopen == "modal" ? "block" : "none" }}
      >
        {oneactivity.map((item) => {
          return (
            <div className="modal-dialog modal-dialog-centered modal-2Inner" key={item._id}>
              <div className="modal-content">
                <div className="modal-header border-0 p-4">
                  <h5 className="modal-title">View Analysis</h5>
                  <button
                    type="button"
                    className="btn-close shadow-none"
                    aria-label="Close"
                    onClick={() => {
                      setModalOpen("");
                    }}
                  ></button>
                </div>
                <div className="modal-body modal_content px-4 py-4">
                  <div className="row" style={{marginRight:'1px',marginLeft:'1px'}} >
                   
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      categories={categories}
                      data1={state[0]}
                     
                    />
                    <div className="col-12"  style={{marginTop:'36px'}}>
                      <div className="pb-2 achievement">Achievement</div>
                    </div>
                    <div className="col-12 py-4 row mx-0">
                      <div className="col-auto ps-0" style={{marginRight:'50px'}}>
                        <div className="fw-noraml distance pb-2 ml-5">Distance</div>
                        <div className="kilomitter">{distance} m</div>
                      </div>
                      <div className="col-auto">
                        <div className="fw-noraml distance pb-2">Time</div>
                        <div className="kilomitter">{length} min</div>
                      </div>
                    </div>
                    <div className="col-12 row mx-0 contant_table py-2 gap-2">
                      <div className="col-12 px-0 row mx-0 align-items-center">
                        <div className="col ps-0 left_side">
                          Fastest lap covered
                        </div>
                        <div className="col-auto pe-0 right_side">
                          {item.metersPerMinute.length ?Math.max(...item?.metersPerMinute) : '0'} m, 1 mins
                        </div>
                      </div>
                      <div className="col-12 px-0 row mx-0 align-items-center">
                        <div className="col ps-0 left_side">
                          Cadence
                          <i className="fw-normal">( steps per minute)</i>
                        </div>
                        <div className="col-auto pe-0 right_side">
                          {item?.steps}
                        </div>
                      </div>
                      <div className="col-12 px-0 row mx-0 align-items-center">
                        <div className="col ps-0 left_side">
                          Distance per minute
                        </div>
                        <div className="col-auto pe-0 right_side">
                          {distance?distance  / length:0} m/min
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChartModal;
