import { useEffect, useState } from "react"

export const useDebounce = (value) =>{
    const [dbValue,setDbValue] = useState()
    useEffect(()=>{
        const timer = setTimeout(()=>{
            setDbValue(value)
        },1000)
        return ()=>clearTimeout(timer)
    },[value])
    return dbValue;
}