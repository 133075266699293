import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setmini, minisearchbar } from "../Redux/Actions";
import { useEffect } from "react";
import { getData } from "../../FetchService";
import Moment from "react-moment";
import Search from "@mui/icons-material/Search";
import LeftSide from "../Section/LeftSide";
import './style.css'

function MiniSideBar() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { miniSide } = useSelector((state) => state);
  const miniSearchState = useSelector((state) => state.miniSearchBar);
  
  const [type, setType] = useState("Clubs");
  const [suggestValue, setSuggestValue] = useState("");
  const [search, setSearch] = useState("");
  const [activeOv, setActiveOv] = useState(false);
  const [result, setResult] = useState([]);

  const getSearch = async (e) => {
   
    setActiveOv(true);
    if (search) {
      const response = await getData(
        `search?search=${search}&offset=${0}&limit=${10}`
      );
      setResult(response?.data);
      // setType("Clubs")
      if (response?.data?.users?.length) {
        setType("Users");
      } else if (response.data?.events?.length) {
        setType("Events");
      } else if (response.data?.clubs?.length) {
        setType("Clubs");
      } else if (response.data?.healthArticles?.length) {
        setType("Health Articles");
      }
    }
  };
  useEffect(() => {
    getSearch();
  }, [search]);

 
  const handleotherProfile = async(id) => {
    
    const res = await getData(`other-user-profile?id=${id}`);
    dispatch(minisearchbar());
    if (res.status == true) {
      navigate("/dashboard/otheruserprofile", { state: { details: id } });
    }
  }
  const handleClubsDetails =  (id) => {
    navigate("/clubdetails", { state: { data: id } });
    dispatch(minisearchbar());
  };
  const handleEventDetails =  (id) => {
    navigate("/events/eventdetailed", { state: { data: id } });
    dispatch(minisearchbar());
  };
  const handleHealthDetails = (id) => {
    navigate("/healtharticle/healthdetails", { state: { data: id } });
    dispatch(minisearchbar());
  };

  return (
    <>
 
      {/* <!--===================== Offcanvas sidebar ======================--> */}
      <div
        className={`offcanvas offcanvas-start sidebarOffcanvas bg-theme2 ${miniSide ? "show" : ""
          }`}
        tabIndex={miniSide ? '-1' : ''}
        id="offcanvasExample"
        role={miniSide ? "dialog" : ""}
        aria-modal={miniSide ? "true" : ""}
        aria-labelledby="offcanvasExampleLabel"
        style={{ display: miniSide ? "block" : "none" }}
      >
        <div className="offcanvas-header p-4 pb-0 align-items-start">
          <NavLink
            to="/dashboard"
            className="offcanvas-title"
            id="offcanvasExampleLabel"
          >
            <img
              className="offcanvasLogo"
              src="/assets/images/image-8.png"
              alt=""
            />
          </NavLink>
          <button
            type="button"
            className="btn p-1 pt-0 border-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              dispatch(setmini());
            }}
          >
            <img
              className="offcanvasClose"
              src="/assets/images/image-72.png"
              alt="image-72"
            />
          </button>
        </div>
        <div  className="offcanvas-body p-4">
          <div className="sidebarItemBox mt-0">
            <NavLink
              className="text-decoration-none text-white itemHover "
              to="/dashboard"
              onClick={() => {
                dispatch(setmini());
              }}
            >
              <div className="me-3 sidebarItem">
                <img
                  src="/assets/images/image-77.png"
                  alt="nav item image"
                  className="sidebarItemImage"
                />
              </div>
              <div
                className="dashboardText text-center"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Dashboard"
              >
                Dashboard
              </div>
              <span className="ms-auto">
                <img
                  className="rightArrow"
                  src="/assets/images/image-165.png"
                  alt="image-165"
                  onClick={() => {
                    dispatch(setmini());
                  }}
                />
              </span>
            </NavLink>
          </div>
          <div className="sidebarItemBox">
            <NavLink
              className="text-decoration-none text-white itemHover"
              to="/events"
              onClick={() => {
                dispatch(setmini());
              }}
            >
              <div className="me-3 sidebarItem">
                <img
                  src="/assets/images/image-78.png"
                  alt="nav item image"
                  className="sidebarItemImage"
                />
              </div>
              <div

                className="dashboardText text-center"
              >Events</div>
              <span className="ms-auto">
                <img
                  className="rightArrow"
                  src="/assets/images/image-165.png"
                  alt="image-165"
                  onClick={() => {
                    dispatch(setmini());
                  }}
                />
              </span>
            </NavLink>
          </div>
          <div className="sidebarItemBox">
            <NavLink
              className="text-decoration-none text-white itemHover"
              to="/clubs"
              onClick={() => {
                dispatch(setmini());
              }}
            >
              <div className="me-3 sidebarItem">
                <img
                  src="/assets/images/image-79.png"
                  alt="nav item image"
                  className="sidebarItemImage"
                />
              </div>
              <div className="dashboardText text-center">Clubs</div>
              <span className="ms-auto">
                <img
                  className="rightArrow"
                  src="/assets/images/image-165.png"
                  alt="image-165"
                  onClick={() => {
                    dispatch(setmini());
                  }}
                />
              </span>
            </NavLink>
          </div>
          <div className="sidebarItemBox">
            <NavLink
              className="text-decoration-none text-white itemHover"
              to="/healtharticle"
              onClick={() => {
                dispatch(setmini());
              }}
            >
              <div className="me-3 sidebarItem">
                <img
                  src="/assets/images/image-80.png"
                  alt="nav item image"
                  className="sidebarItemImage"
                />
              </div>
              <div className="dashboardText text-center">Health Articles</div>
              <span className="ms-auto">
                <img
                  className="rightArrow"
                  src="/assets/images/image-165.png"
                  alt="image-165"
                  onClick={() => {
                    dispatch(setmini());
                  }}
                />
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      {/* <!--===================== top Offcanvas searchbar ======================--> */}
      <div
      
        className={`offcanvas offcanvas-top border-0 vh-100 searchTopoffcanvas ${miniSearchState ? "show" : ""
          } `}
        tabIndex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
        // aria-labelledby="offcanvasTopLabel"
        aria-modal={`${miniSearchState ? "true" : ""}`}
        role={`${miniSearchState ? "dialog" : ""}`}
      >
        <div className="offcanvas-header pb-0">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            What are you Looking for?
          </h5>
          <button
            type="button"
            className="btn p-1 border-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              dispatch(minisearchbar());
            }}
          >
            <img
              className="offcanvasClose"
              src="/assets/images/image-72.png"
              alt=""
            />
          </button>
        </div>
        <div  className="offcanvas-body">
          <form className="position-relative w-100 headerSearch">
            <input
              className="form-control shadow-none bg-light border-0 w-100 searchInput"
              type="search"
              placeholder="Search here"
              aria-label="Search"
              onChange={(e) =>{ setSearch(e.target.value); setType(""); setActiveOv(true)}}
              onKeyPress={(event) => {
                if (event.charCode == 13) {
                  event.preventDefault(event); 
                }
              }}
            />
            <img
              className="SearchIcon"
              src="/assets/images/image-71.png"
              alt="image-71"
            />

            {search ? (
              <div className="position-absolute w-100 bg-white dropdownTabs">
                <ul
                  className="nav nav-pills justify-content-between mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item"
                    onClick={() => setType("Users")}
                    role="presentation"
                  >
                    <button
                      className={`nav-link rounded-0 border-0 px-1 pt-0 bg-transparent ${type == "Users" ? "active" : ""
                        }`}
                      id="users2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#users2"
                      type="button"
                      role="tab"
                      aria-controls="users2"
                      aria-selected="true"
                    >
                      Users
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => setType("Events")}
                    role="presentation"
                  >
                    <button
                      className={`nav-link rounded-0 border-0 px-1 pt-0 bg-transparent ${type == "Events" ? "active" : ""
                        }`}
                      id="events2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#events2"
                      type="button"
                      role="tab"
                      aria-controls="events2"
                      aria-selected="false"
                    >
                      Events
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => setType("Clubs")}
                    role="presentation"
                  >
                    <button
                      className={`nav-link rounded-0 border-0 px-1 pt-0 bg-transparent ${type == "Clubs" ? "active" : ""
                        }`}
                      id="clubs2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#clubs2"
                      type="button"
                      role="tab"
                      aria-controls="clubs2"
                      aria-selected="false"
                    >
                      Clubs
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => setType("Health Articles")}
                    role="presentation"
                  >
                    <button
                      className={`nav-link rounded-0 border-0 px-1 pt-0 bg-transparent ${type == "Health Articles" ? "active" : ""
                        }`}
                      id="health-articles2-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#health-articles2"
                      type="button"
                      role="tab"
                      aria-controls="health-articles2"
                      aria-selected="false"
                    >
                      Health Articles
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {type == "Users"
                    ? result?.users?.map((item) => {
                      return (
                        // <NavLink to={"/dashboard/profile"}>
                        <div
                          style={{ left: "0px", width: "100%" }}
                          id="users"
                          role="tabpanel"
                          aria-labelledby="users-tab"
                          tabIndex="0"
                          key={Math.random()}
                        >
                          <div className="row g-2 align-items-center userDiv">
                            <div className="col-auto">
                              <div className="userimgSearch">
                              <img
                                className="followerImg"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "50%",
                                  objectFit:"cover",
                                }}
                                src={
                                  item.image
                                    ? item.image
                                    : "/assets/images/image-148.png"
                                }
                                alt="image-148"
                              />

                              </div>
                            </div>
                            <div className="col">
                              <div
                                className="fw-bold userEllipsis"
                                style={{cursor : 'pointer'}}
                                onClick={() => {
                                  handleotherProfile(item._id)
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </div>
                            </div>
                          </div>
                        </div>
                        // </NavLink>
                      );
                    })
                    : ""}
                  {type == "Events"
                    ? result?.events?.map((item) => {
                      return (
                        <div
                          style={{ left: "20px" }}
                          id="events"
                          role="tabpanel"
                          aria-labelledby="events-tab"
                          tabIndex="0"
                          key={Math.random()}
                        >
                          <div className="row g-2 align-items-center userDiv">
                            <div className="col-auto">
                              <div className="userimgSearch">
                              <img
                                className="followerImg"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "50%",
                                  objectFit:"cover",
                                }}
                                src={
                                  item.image
                                    ? item.image
                                    : "/assets/images/image-262.png"
                                }
                                alt="image-262"
                              />

                              </div>
                            </div>
                            <div className="col">
                              <div className="fw-bold userEllipsis"
                               style={{ cursor: "pointer" }}
                               onClick={() => handleEventDetails(item)}
                              >{item.template}</div>
                              <div className="updatetime">
                                <Moment format="MMMM Do">
                                  {item.startDate} to {item.endDate} :
                                  {item.startDate}-{item.endDate} until start
                                </Moment>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                  {type == "Clubs"
                    ? result?.clubs?.map((item) => {
                      return (
                        <div
                          style={{ left: "40px" }}
                          id="clubs"
                          role="tabpanel"
                          aria-labelledby="clubs-tab"
                          tabIndex="0"
                          key={Math.random()}
                        >
                          <div className="row g-2 align-items-center userDiv">
                            <div className="col-auto">
                              <div className="userimgSearch">
                              <img
                                className="followerImg"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "50%",
                                  objectFit:"cover",
                                }}
                                src={
                                  item.image
                                    ? item.image
                                    : "/assets/images/image-154.png"
                                }
                                alt="image-154"
                              />

                              </div>
                            </div>
                            <div className="col">
                              <div className="fw-bold userEllipsis"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClubsDetails(item)}
                              >{item.name}</div>
                              <div className="updatetime">
                                Created on :{" "}
                                <Moment format="MMM Do YY">
                                  {item.createdAt}
                                </Moment>
                              </div>
                            </div>
                            <div className="col-auto">
                              {item.isPrivate == true ? (
                                <button
                                  type="button"
                                  className="btn border-0 shadow-none followBtn"
                                >
                                  Private
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn border-0 shadow-none followBtn"
                                >
                                  Public
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                      {type == "Health Articles"
                        ? result?.healthArticles?.map((item) => {
                         
                          return (
                            <div
                            id="health-articles"
                            role="tabpanel"
                            aria-labelledby="health-articles-tab"
                            tabIndex="0"
                            key={Math.random()}
                          >
                            <div className="row g-2 align-items-center userDiv">
                              <div className="col-auto">
                                <div className="userimgSearch">
                                <img
                                  className="followerImg"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "50%",
                                    objectFit:"cover",
                                  }}
                                  src={
                                    item.image
                                      ? item.image
                                      : "/assets/images/image-180.png"
                                  }
                                />

                                </div>
                              </div>
                              <div className="col">
                                <div className="fw-bold userEllipsis" style={{ cursor: "pointer" }}
                                    onClick={() => handleHealthDetails(item)}>{item.name}</div>
                                <div className="updatetime">
                                  <Moment format="MMMM Do YYYY, h:mm">
                                    {item.createdAt}
                                  </Moment>
                                </div>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn border-0 shadow-none followBtn"
                                >
                                  Running
                                </button>
                              </div>
                            </div>
                          </div>
                          );
                        })
                        : ""}
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
}

export default MiniSideBar;
